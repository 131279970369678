import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { pickAddress, setShipmentOption } from '../../features/checkout/checkoutSlice';
import { eventBus } from '../../helpers/eventBus';
import { SvgIcon } from '../common/SvgIcon/SvgIcon';
import { Text } from '../Text/Text';
import { CheckoutNewAddressForm } from './CheckoutNewAddressForm';

import './checkoutAddressTile.scss';
import { capitalizeFirstLetter } from '../../helpers/textHelpers';

export const CheckoutAddressTile = ({
  id,
  purpose,
  name,
  phone,
  email,
  addressLine1,
  addressLine2,
  zipCode,
  city,
  country,
  addNew,
  selected,
}) => {
  const { t } = useTranslation('application');
  const dispatch = useDispatch();

  const addNewAddress = () => {
    eventBus.publish('modal:open', { title: t(`checkout.new${capitalizeFirstLetter(purpose)}Address`), body: <CheckoutNewAddressForm purpose={purpose} />, fixedWidth: true });
  };

  const handleAddressPick = (type, addressId) => {
    dispatch(
      pickAddress({
        type,
        address: addressId,
      }),
    );
    if (type === 'billing') return;
    dispatch(
      setShipmentOption('delivery'),
    );
  };

  useEffect(() => {
    if (purpose === 'billing' || !selected) return;
    dispatch(
      setShipmentOption('delivery'),
    );
  }, []);

  if (addNew) {
    return (
      <button type="button" className="checkout-address-tile checkout-address-tile--add" onClick={() => addNewAddress()}>
        <SvgIcon id="icon-map-location" width={72} height={72} />
        <Text type="body2">
          {t('checkout.addAddress')}
        </Text>
      </button>
    );
  }
  return (
    <button
      type="button"
      className={`checkout-address-tile ${selected && 'selected'}`}
      onClick={() => handleAddressPick(purpose, id)}
    >
      <div className="checkout-address-tile__check" />
      <Text className="checkout-address-tile__text" type="body2">
        {name}
      </Text>
      <Text className="checkout-address-tile__text" type="body2">
        {phone}
      </Text>
      <Text title={email} className="checkout-address-tile__text" type="body2">
        {email}
      </Text>
      <Text className="checkout-address-tile__text" type="body2">
        {`${addressLine1}, ${addressLine2}`}
      </Text>
      <Text className="checkout-address-tile__text" type="body2">
        {`${zipCode}, ${city}, ${country}`}
      </Text>
    </button>
  );
};
