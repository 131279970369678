import i18next from 'i18next';
import React from 'react';
import { Button } from '../common/Button/Button';

import './langaugeSwitcher.scss';

export const LanguageSwitcher = () => {
  /* eslint-disable immutable/no-mutation */
  const handleLanguageChange = () => {
    if (i18next.language === 'en') {
      i18next.changeLanguage('ar');
      document.documentElement.lang = 'ar';
      document.documentElement.dir = 'rtl';
      localStorage.setItem('preferred_language', 'ar');
    } else {
      i18next.changeLanguage('en');
      document.documentElement.lang = 'en';
      document.documentElement.dir = 'ltr';
      localStorage.setItem('preferred_language', 'en');
    }
  };

  return (
    <Button
      type="naked"
      className="language-switcher"
      onClick={handleLanguageChange}
      style={{ fontWeight: i18next.language === 'en' ? '900' : '400' }}
      text={i18next.language === 'en' ? 'ع' : 'EN'}
    />
  );
};
