import React, { useState } from 'react';
import ProductPlaceholderImg from '../../assets/icons/product-placeholder.svg';

export const ProductImage = ({ source, alt, className }) => {
  const [error, setError] = useState(null);
  const { API_ROOT } = process.env;

  if (!source || error) {
    return <ProductPlaceholderImg className={`product-image ${className ?? ''}`} />;
  }
  return <img src={`${API_ROOT}${source}`} onError={() => setError(true)} alt={alt} className={`product-image ${className ?? ''}`} />;
};
