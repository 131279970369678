import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '../../components/Text/Text';
import { convertToFinalPaymentValue } from '../../helpers/productHelpers';
import { useCreatePaymentMutation } from '../../services/order';
import {
  setDiscountCode,
  setOrderPrices,
  setPaymentData,
  setCurrentStep,
  setPendingPayment,
} from '../../features/checkout/checkoutSlice';

import './paymentForm.scss';

export const PaymentForm = () => {
  const { t, i18n } = useTranslation('application');
  const { PUBLIC_URL, MOYASAR_API_KEY } = process.env;
  const paymentDescription = (paymentId) => `Payment ${paymentId}`;
  const isPaymentPending = useSelector((state) => state.checkout.pendingPayment);
  const dispatch = useDispatch();
  const paymentData = useSelector((state) => state.checkout.paymentData);
  const [createPayment] = useCreatePaymentMutation();

  useEffect(() => {
    if (isPaymentPending) {
      createPayment()
        .unwrap()
        .then((data) => {
          dispatch(setPaymentData(data));
          dispatch(
            setOrderPrices({
              summaryPrice: data.order.summaryPrice,
              totalPrice: data.order.totalPrice,
              discountValue: data.order.discountValue,
              shipmentPrice: data.order.shipmentPrice,
              vatPrice: data.order.vatPrice,
            }),
          );
          if (data.usedDiscountCode) {
            dispatch(setDiscountCode(data.usedDiscountCode));
          }
        })
        .catch((errorResponse) => {
          if (errorResponse.data.errors.order.some((message) => message === 'invalid_state')) {
            dispatch(setCurrentStep(1));
            dispatch(setPendingPayment(false));
          }
        });
    }
  }, []);

  useEffect(() => {
    if (!paymentData) return;
    // eslint-disable-next-line no-undef
    Moyasar.init({
      element: '.mysr-form',
      amount: convertToFinalPaymentValue(paymentData.amount),
      currency: 'SAR',
      description: paymentDescription(paymentData?.metadata?.fingerprint),
      publishable_api_key: MOYASAR_API_KEY,
      callback_url: `${PUBLIC_URL}/payment/validate`,
      methods: ['applepay', 'creditcard', 'stcpay'],
      apple_pay: {
        country: 'SA',
        label: 'CamelStep',
        validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
      },
      metadata: {
        fingerprint: paymentData?.metadata?.fingerprint,
        orderNumber: paymentData?.metadata?.order_number,
      },
      language: i18n.language,
    });
  }, [paymentData]);

  return (
    <div className="payment-wrapper">
      <Text type="headline2" style={{ marginBlockEnd: 20 }}>
        {t('payments.title')}
      </Text>
      <div className="mysr-form" />
    </div>
  );
};
