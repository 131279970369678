import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from './Button';
import { PlusButton } from './PlusButton';
import { useCreateCartPackageMutation, useCreateCartProductMutation } from '../../../services/cart';
import { notify } from '../../../utils/notifications';
import { buildErrorMsg } from '../../../helpers/errorHelpers';
import { setCurrentStep } from '../../../features/checkout/checkoutSlice';

export const AddToCartButton = ({
  productId, hasAddons, buttonType = 'standard',
  disabled, buttonText, isPackage,
}) => {
  const { t } = useTranslation('application');
  const navigate = useNavigate();
  const [addToCart, { isSuccess }] = isPackage ? useCreateCartPackageMutation() : useCreateCartProductMutation();
  const checkoutCurrentStep = useSelector((state) => state.checkout.currentStep);
  const dispatch = useDispatch();

  const handleAddToCart = async (id) => {
    const addToCartSchema = { quantity: 1 };

    /* eslint-disable immutable/no-mutation */
    if (isPackage) addToCartSchema.package_id = id;
    else addToCartSchema.product_id = id;

    if (hasAddons) {
      navigate(`/products/${productId}`);
    } else {
      const { error: { data: { errors } = {} } = {} } = await addToCart(addToCartSchema);
      if (errors) {
        notify(buildErrorMsg(errors), 'warning');
      }

      if (checkoutCurrentStep === 3) {
        dispatch(setCurrentStep(1));
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notify(t('cart.addToCartSuccess'), 'success');
    }
  }, [isSuccess]);

  const addToCartHelper = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    handleAddToCart(productId);
  };

  if (buttonType === 'plus') {
    return <PlusButton disabled={disabled} onClick={addToCartHelper} />;
  }

  if (buttonType === 'standard') {
    return (
      <Button
        disabled={disabled}
        text={buttonText || t('common.addToCart')}
        type="primary"
        onClick={addToCartHelper}
        className="add-to-cart-button"
      />
    );
  }

  if (buttonType === 'secondary') {
    return (
      <Button
        disabled={disabled}
        text={buttonText || t('common.addToCart')}
        type="naked"
        onClick={addToCartHelper}
        className="add-to-cart-button-underlined"
      />
    );
  }
  return null;
};
