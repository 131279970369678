import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Col, Row } from 'react-grid-system';
import { toast } from 'react-toastify';
import { Text } from '../../../components/Text/Text';
import { eventBus } from '../../../helpers/eventBus';
import {
  useConfirmNewPhoneNumberMutation,
  useGetPhoneNumberQuery,
  useGetProfileDetailsQuery,
  useUpdatePhoneNumberMutation,
  useUpdateProfileDetailsMutation,
  useUpdateEmailDetailsMutation,
} from '../../../services/user';
import { NameForm } from './Forms/NameForm';
import { PhoneNumberChangeForm } from './Forms/PhoneNumberChangeForm';
import { PhoneNumberValidationForm } from './Forms/PhoneNumberValidationForm';

import './profileDetails.scss';
import { EmailChangeForm } from './Forms/EmailChangeForm';

export const ProfileDetails = () => {
  const { t } = useTranslation('application', { keyPrefix: 'profile' });
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: userDetails } = useGetProfileDetailsQuery();
  const { data: phoneNumberData } = useGetPhoneNumberQuery();

  const [updateProfileDetails] = useUpdateProfileDetailsMutation();
  const [updateEmailDetails] = useUpdateEmailDetailsMutation();
  const [updatePhoneNumber, { error: phoneNumberError }] = useUpdatePhoneNumberMutation();
  const [confirmPhoneNumber, { error: otpConfirmError }] = useConfirmNewPhoneNumberMutation();
  const [status, setStatus] = useState({
    type: '',
    message: '',
  });
  const [emailStatus, setEmailStatus] = useState({
    type: '',
    message: '',
  });

  const handleNameFormSubmit = async (values) => {
    const { firstName, lastName } = values;

    try {
      await updateProfileDetails({
        first_name: firstName,
        last_name: lastName,
      })
        .unwrap()
        .then(() => {
          setStatus({
            type: 'success',
            message: t('profileDetails.updateSuccess'),
          });
        });
    } catch (anError) {
      const error = Object.values(anError?.data?.errors)?.[0]?.[0];
      setStatus({
        type: 'danger',
        message: error,
      });
    }
  };

  const handleEmailFormSubmit = async (values) => {
    const { email } = values;

    try {
      await updateEmailDetails({
        email,
      })
        .unwrap()
        .then(() => {
          setEmailStatus({
            type: 'success',
            message: t('emailDetails.sent'),
          });
        });
    } catch (anError) {
      const error = Object.values(anError?.data?.errors)?.[0]?.[0];
      setEmailStatus({
        type: 'danger',
        message: error,
      });
    }
  };

  const handleConfirmPhoneNumber = async (code) => {
    try {
      await confirmPhoneNumber(code);
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdatePhoneNumber = (values) => {
    const { phoneNumber } = values;

    updatePhoneNumber(phoneNumber)
      .unwrap()
      .then(() => {
        eventBus.publish('modal:open', {
          body: (
            <PhoneNumberValidationForm
              onSubmit={handleConfirmPhoneNumber}
              error={otpConfirmError}
              handleResendCode={updatePhoneNumber}
              phoneNumber={phoneNumber}
            />
          ),
          alertType: true,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const result = searchParams.get('email_confirmation_result');
    let timeOut = null;
    if (!result) return;
    if (result === 'success') {
      setEmailStatus({
        type: 'success',
        message: t('emailDetails.updateSuccess'),
      });
      toast(t('emailDetails.updateSuccess'), {
        type: 'success',
      });
    }
    if (result === 'failure') {
      setEmailStatus({
        type: 'danger',
        message: t('emailDetails.updateFailed'),
      });
      toast(t('emailDetails.updateFailed'), {
        type: 'error',
      });
    }
    setSearchParams('');
    timeOut = setTimeout(() => {
      setEmailStatus({ type: '', message: '' });
    }, 15000);
    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <div className="profile-subpage profile-details">
      <Text style={{ marginBottom: '2rem' }} type="headline3">
        {t('nav.details')}
      </Text>
      <NameForm
        onSubmit={handleNameFormSubmit}
        firstName={userDetails?.first_name}
        lastName={userDetails?.last_name}
        email={userDetails?.email}
        status={status}
      />
      <Row>
        <Col xs={12} md={6}>
          <Text style={{ margin: '2rem 0' }} type="headline3">
            {t('nav.email')}
          </Text>
          <EmailChangeForm
            onSubmit={handleEmailFormSubmit}
            email={userDetails?.email}
            status={emailStatus}
          />
        </Col>
        <Col xs={12} md={6}>
          <Text style={{ margin: '2rem 0' }} type="headline3">
            {t('nav.login')}
          </Text>
          <PhoneNumberChangeForm
            onSubmitPhoneNumber={handleUpdatePhoneNumber}
            initialPhoneNumber={phoneNumberData?.phone_number}
            error={phoneNumberError?.data?.errors?.phone_number[0]}
          />
        </Col>
      </Row>
    </div>
  );
};
