import BourbonImage1 from '../assets/images/bourbon-image-1.png';
import BourbonImage2 from '../assets/images/bourbon-image-2.png';
import BourbonImage3 from '../assets/images/bourbon-image-3.png';

export const bourbonCardsData = [
  {
    id: '1',
    to: '/quiz',
    imageSrc: BourbonImage1,
    title: 'bourbonSection.card1.title',
    subtitle: 'bourbonSection.card1.subtitle',
  },
  {
    id: '2',
    to: '/how_to_brew',
    imageSrc: BourbonImage2,
    title: 'bourbonSection.card2.title',
    subtitle: 'bourbonSection.card2.subtitle',
  },
  {
    id: '3',
    to: '/about_us',
    imageSrc: BourbonImage3,
    title: 'bourbonSection.card3.title',
    subtitle: 'bourbonSection.card3.subtitle',
  },
];
