export const data = {
  en: [
    {
      title: 'Shipping and delivery',
      questions: [
        {
          q: 'Can I find Camel Step’s coffee beans within food and beverages delivery apps?',
          a: 'Yes, you can order via the following delivery apps: Jahez, The Chefz, Marsool.',
        },
        {
          q: 'Do you have international shipping?',
          a: 'Camel Step services reach our customers in the Kingdom of Saudi Arabia, the United Arab Emirates, Kuwait, Bahrain,Oman and the United kingdom by simply completing the order and choosing your country. If you want us to ship to an unlisted country,\n\nkindly contact us via WhatsApp 966920000689.',
        },
        {
          q: 'Was my order shipped?',
          a: 'When your order is shipped, you will receive an email with the carrier\'s tracking number and a text message on the contact info that were entered when creating your order. You can also track the status of your order through your control panel from the user panel on our website',
        },
        {
          q: 'When will my order be shipped?',
          a: 'Usually, the orders are processed with a maximum of two working days from the date of purchase.',
        },
        {
          q: 'How can I track my order?',
          a: 'When we ship your order and hand it to the carrier, you can receive email to your order’s email address (if it was not in the inbox kindly check the spam/junk mail) containing a tracking number that can be used in the carrier\'s website.',
        },
        {
          q: 'How long does my order take to arrive?',
          a: 'Delivery inside Saudi Arabia takes 3 - 5 working days, while for international shipping it may take 5 - 10 working days',
        },
      ],
    },
    {
      title: 'Warranty and maintenance',
      questions: [
        {
          q: 'How long is the warranty period?',
          a: `Two years from the date of purchase, and to learn more about the warranty please see the warranty policy ${window.location.origin}/policy`,
        },
        {
          q: 'Does Camel Step’s maintenance center repair out-of-warranty devices?',
          a: 'Yes, we are happy to provide our maintenance center services and please contact us via email Maintenance@Camelstep.com',
        },
      ],
    },
    {
      title: 'Orders and services',
      questions: [
        {
          q: 'The product I want is not available. What should i do?',
          a: 'We apologize for not having the desired product, you can contact us to be notified if the product is available via WhatsApp 966920000689.',
        },
        {
          q: 'Can my order be canceled?',
          a: 'Yes, and only if the order status was still processing and still hasn\'t been shipped, you can reach us and one of our employees will reply to you as soon as possible via WhatsApp 966920000689.',
        },
        {
          q: 'I received a wrong order, so what should I do?',
          a: 'We apologize and all you have to do is contact us and we will assist you in no more than 24 hours via WhatsApp 966920000689.',
        },
        {
          q: 'Can I replace a product?',
          a: 'We apologize that currently you cannot replace any product, and you can contact us via WhatsApp 966920000689.',
        },
        {
          q: 'I received a product and want to return it?',
          a: 'We are sorry to hear that, it is possible to return the product if it is in its original condition Please contact us and we will reply within two working days via WhatsApp 966920000689.',
        },
      ],
    },
    {
      title: 'Payment methods',
      questions: [
        {
          q: 'What are the available payment methods?',
          a: 'We accept payments by credit cards Visa and MasterCard, including debit cards and mada. \n Apple Pay service, which accepts most payment cards.',
        },
        {
          q: 'Is it possible to pay by American Express?',
          a: 'It is not possible to pay directly now, but you can add an American Express card to the Apple Pay service and then pay by it.',
        },
        {
          q: 'Is cash on delivery service available?',
          a: 'We apologize, this service currently not available.',
        },
      ],
    },
    {
      title: 'Other inquiries',
      questions: [
        {
          q: 'I am interested in doing business with Camel Step. How can I do that?',
          a: 'We are always happy to expand our partners. All you need to do is to fill in the following form and one the business partners team members will contact you as soon as possible. https://b2b.camelstep.com/web/register',
        },
        {
          q: 'Want to know the types of coffee and the methods of preparation?',
          a: 'You can visit Camel Step’s Blog for more information about coffee and its recipes https://camelstep.com/blogs',
        },
        {
          q: 'Are the training programs provided by Camel Step approved by the Speciality Coffee Association?',
          a: 'Yes, and trainers will receive an authenticated certificate with the presented SCA training programs by Camel Step',
        },
        {
          q: 'I want to add store credit in my account or to present it as a gift to a friend?',
          a: 'You can contact us via whatsapp 966920000689',
        },
        {
          q: 'Can I have my ordered coffee beans grounded?',
          a: 'We apologize that currently we do not provide the grinding services for quality measurements. If you still want to enjoy your coffee grounded, we are happy to serve you through one of our branches',
        },
      ],
    },
    {
      title: 'Orders Store Pick up',
      questions: [
        {
          q: 'What is the Pick-up service?',
          a: 'Pick-up service enables the customers to place an order from our website and pick it up at one of Camel Step stores.',
        },
        {
          q: 'How long will my order be held at the store?',
          a: 'Your order will be held in the store for 5 business days Then the order will be considered as cancelled.',
        },
        {
          q: 'Can I change an existing online order to Pick up from the store?',
          a: 'If you would like to change the delivery method and the order status is still processing please contact us via WhatsApp 966920000689',
        },
      ],
    },
  ],
  ar: [
    {
      title: 'الشحن والتوصيل',
      questions: [
        {
          q: ' هل توجد تطبيقات توصيل مباشرة؟',
          a: 'نعم، بامكانكم الطلب عن طريق تطبيقات التوصيل التالية: جاهز، ذا شفز، مرسول.',
        },
        {
          q: ' هل هناك شحن دولي؟',
          a: 'نحن في خطوة جمل نقوم بخدمة جميع عملائنا في المملكة العربية السعودية والإمارات العربية المتحدة والكويت والبحرين وعمان عن طريق إنشاء الطلب من خلال موقعنا الإلكتروني.اما بخصوص الشحن الدولي یرجی التواصل معنا لمعرفة المزيد حول الخدمة عبر الواتساب 966920000689',
        },
        {
          q: ' هل تم شحن طلبي؟',
          a: 'عند شحن طلبك يتم تزويدك برقم التتبع عبر الايميل المسجل عند إنشاء الطلب و رسالة نصية على رقم التواصل المدخل عند إنشاء الطلب، كما يمكن متابعة حالة الطلب من خلال لوحة التحكم الخاصة بك في الموقع.',
        },
        {
          q: ' متى سيتم شحن طلبي؟ ',
          a: 'عادة يتم تجهيز الطلب بحد أقصى خلال يومي عمل من تاريخ إنشاء الطلب .',
        },
        {
          q: ' كيف يمكن متابعة طلبي؟',
          a: 'عند تسليم الطلب للناقل يتم تزويدك تلقائيا برقم التتبع.',
        },
        {
          q: ' ما هي المدة التي يستغرقها طلبي للوصول؟ ',
          a: 'تستغرق مدة التوصيل للشحن العادي داخل المملكة العربية السعودية من 3 - 5 أيام عمل أما لدول الخليج قد تستغرق من 5 - 10 أيام عمل.',
        },
      ],
    },
    {
      title: 'الضمان والصيانة',
      questions: [
        {
          q: ' كم مدة الضمان؟',
          a: `سنتين من تاريخ الشراء، ولمعرفة المزيد حول الضمان الرجاء الإطلاع على سياسة الضمان ${window.location.origin}/policy`,
        },
        {
          q: ' هل مركز الصيانة الخاص بخطوة جمل يقبل إصلاح الأجهزة الخارجة عن الضمان؟',
          a: 'نعم، مركز الصيانة لدى خطوة جمل یسعد بتقديم خدماته ولمزيد من المعلومات نأمل منك التواصل معنا عبر الایمیل التالي Maintenance@Camelstep.com',
        },
      ],
    }, {
      title: 'الطلبات',
      questions: [
        {
          q: ' المنتج الذي ارغب به غير متوفر ما العمل؟',
          a: 'الواتساب 966920000689 نعتذر لعدم توفر المنتج المرغوب، يمكنك التواصل معنا ليتم اشعارك في حال توفر المنتج',
        },
        {
          q: ' هل يمكن إلغاء طلبي؟',
          a: 'نعم يمكن إلغاء طلبك قبل تسليم الشحنة للناقل دون تحمل أي رسوم. علما بأن استرداد المبلغ يكون لحساب البطاقة المستخدم في عملية الشراء أو كرصيد لحساب العميل في الموقع كل ماعليك التواصل معنا عبر الواتساب 966920000689',
        },
        {
          q: ' هل يمكن التعديل على طلبي؟',
          a: 'في حال كان الطلب تحت المعالجة يمكن التحدث إلينا عبر الواتساب 966920000689 وسيقوم أحد موظفينا بالرد عليك في أسرع وقت ممكن .',
        },
        {
          q: ' استلمت طلب خاطئ فما العمل؟',
          a: 'نعتذر منك وكل ما عليك فعله التواصل معنا عبر الواتساب 966920000689 سنقوم بمساعدتك في مدة لا تزيد عن 24 ساعة.',
        },
        {
          q: ' استلمت منتج وارغب باستبداله؟',
          a: 'نعتذر لا يمكن العمل على استبدال أي منتج ويمكنك التواصل معنا عن طريق الواتس اب 966920000689.',
        },
        {
          q: ' استلمت منتج وارغب باسترجاعه؟',
          a: 'يؤسفنا أن المنتج لم يرضيك، يمكن العمل على استرجاع المنتج إذا كان في حالته الأصلية نرجو التواصل معنا عبر الواتساب 966920000689',
        },
      ],
    }, {
      title: 'طرق الدفع',
      questions: [
        {
          q: ' ماهي وسائل الدفع المتوفرة؟',
          a: 'يقبل موقع خطوة جمل الدفع عن طريق بطاقات الائتمان فيزا و ماستركارد وبما في ذلك البطاقات مسبقة الدفع و مدى. ايضا تم اطلاق خدمة الدفع عن طريق أبل باي التي تقبل اغلب بطاقات الدفع بما فيها امريكان اكسبرس.',
        },
        {
          q: ' هل يمكن الدفع عن طريق بطاقة امريكان اكسبرس؟',
          a: 'لا يمكن الدفع مباشره حاليا ولكن تستطيع إضافة بطاقة امريكان اكسبرس لخدمة ابل باي ومن ثم الدفع عن طريقها.',
        },
        {
          q: ' هل خدمة الدفع عند الاستلام متوفرة؟',
          a: 'نعتذر هذه الخدمة غير متوفرة.',
        },
      ],
    }, {
      title: 'استفسارات أخرى',
      questions: [
        {
          q: ' لدي رغبة بالتعامل التجاري مع خطوة جمل كيف يمكنني ذلك؟',
          a: 'نسعد دوما لتوسيع قاعدة شركائنا، كل ما عليك هو تعبئة النموذج التالي وسيتم التواصل معك من قبل فريق شركاء الأعمال https://b2b.camelstep.com/web/register',
        },
        {
          q: ' لدي الرغبة في معرفة أنواع البُن وطرق التحضير؟',
          a: 'يمكنك زيارة مدونة خطوة جمل لمزيد من المعلومات حول القهوة ووصفاتها https://camelstep.com/blogs',
        },
        {
          q: ' هل البرامج المقدمة من قبل بخطوة جمل معتمدة من قبل منظمة القهوة العالمية ؟',
          a: 'نعم ، ويتم تقديمها من قبل مدربين معتمدين.',
        },
        {
          q: ' أريد إضافة رصيد للمشتريات في حسابي أو تقديمه كهدية لصديق ؟',
          a: 'بإمكانك التواصل معنا عبر الواتس اب 966920000689',
        },
        {
          q: ' هل خدمة الطحن متوفرة ؟',
          a: 'نعتذر منك لعدم توفر خدمة الطحن في الوقت الحالي في حال رغبتك في الطحن نأمل منك التوجه إلى أحدى فروعنا .',
        },
      ],
    }, {
      title: 'الاستلام من الفروع',
      questions: [
        {
          q: ' ماهي خدمة الاستلام من الفرع ؟ ',
          a: 'تقدم شركة خطوة جمل خدمة الاستلام من الفرع والتي تعني أنه بإمكان العميل إتمام عملية شراء المنتجات من الموقع الالكتروني واستلامها من أحد الفروع.',
        },
        {
          q: ' ماهي مدة تخزين طلبي داخل الفرع ؟ ',
          a: 'سوف يتم الاحتفاظ بالطلب داخل الفرع لمدة 5 أيام وفي حال عدم الاستلام يعتبر الطلب ملغي.',
        },
        {
          q: ' كيف أعرف أن الطلب جاهز للاستلام؟ وما المطلوب مني احضاره حال استلام الطلب؟ ',
          a: 'سوف تصلك رسالة نصية أو رسالة عبر البريد الالكتروني المسجل عند إنشاء الطلب عندما يصبح الطلب جاهز للاستلام مع موقع الفرع، توجه للفرع المعني وكل ماعليك هو تزويد أحد الموظفين بصورة من اشعار استلام الطلب.',
        },
        {
          q: ' قمت باختيار شركة شحن لتوصيل الطلب الخاص بي هل أستطيع التغيير إلى خدمة الاستلام من الفرع؟',
          a: 'في حال قمت بتغيير رغبتك وحالة الطلب مازالت تحت المعالجة فإنه يمكن لممثلنا المساعدة في تغيير ذلك حسب رغبتك. فضلا تواصل معنا عبر الواتس اب 966920000689',
        },
      ],
    },
  ],
};
