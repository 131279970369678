import { createSlice } from '@reduxjs/toolkit';

const initialState = { isOpen: false };

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    toggleCartModal: (state) => {
      state.isOpen = !state.isOpen; //eslint-disable-line
    },
  },
});

export const { toggleCartModal } = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
