import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { CustomSelect } from '../inputs/CustomSelect/CustomSelect';
import {
  branchesLocation, branchesCountriesOptions, branchesCitiesOptions, mapCenter, mapContainerStyle, customMapIcon,
} from '../../utils/branchesLocation';
import { GOOGLE_MAP_API_KEY } from '../../config';
import MarkerIcon from '../../assets/icons/marker.svg';
import './OurBranchesSection.scss';

export const OurBranchesSection = ({ branchesProp }) => {
  const { t, i18n } = useTranslation('application');

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    language: i18n.language,
  });

  const mapRef = useRef();
  const branchCardRefs = useRef([]);

  const [mapState, setMapState] = useState({
    selectedLocation: mapCenter,
    branchesData: branchesLocation.filter((branch) => branch.country === 'saudia'),
    selectedCountry: 'saudia',
    selectedCity: 'all',
    cityList: branchesCitiesOptions.filter((city) => city.country.includes('saudia')),
  });

  const handleButtonClick = (location) => {
    if (mapState.selectedLocation === location) {
      setMapState({
        ...mapState,
        selectedLocation: mapCenter,
      });
      mapRef.current.panTo(mapCenter);
    } else {
      setMapState({
        ...mapState,
        selectedLocation: location,
      });
      mapRef.current.panTo(location);
    }
  };

  const handleMapLoad = useCallback((map) => {
    // eslint-disable-next-line immutable/no-mutation
    mapRef.current = map;
    mapRef.current.setCenter(mapCenter);
  }, [mapRef]);

  const countrySelectHandler = (value) => {
    const selectCountry = value;
    if (selectCountry === 'all') {
      setMapState({
        ...mapState,
        branchesData: branchesLocation,
        selectedCountry: selectCountry,
        selectedCity: 'all',
      });
      return;
    }
    const updatedBranchList = branchesLocation.filter((branch) => branch.country === selectCountry);
    const updatedCitiesList = branchesCitiesOptions.filter((city) => city.country.includes(selectCountry));
    setMapState({
      ...mapState,
      branchesData: updatedBranchList,
      selectedCountry: selectCountry,
      cityList: updatedCitiesList,
      selectedCity: 'all',
    });
  };

  const citySelectHandler = (value) => {
    const selectCity = value;

    if (selectCity === 'all') {
      const updatedBranchList = branchesLocation.filter((branch) => branch.country === mapState.selectedCountry);
      setMapState({
        ...mapState,
        branchesData: updatedBranchList,
        selectedCity: selectCity,
      });
      return;
    }

    const updatedBranchList = branchesLocation.filter((branch) => branch.city === selectCity);
    setMapState({
      ...mapState,
      branchesData: updatedBranchList,
      selectedCity: selectCity,
    });
  };

  const countryLabel = t('mapSection.country');
  const cityLabel = t('mapSection.city');

  const handleMarkerClick = (e, location, index) => {
    setMapState({
      ...mapState,
      selectedLocation: location,
    });
    const branchRef = branchCardRefs.current[index];
    if (branchRef) {
      branchRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const setBranchRef = (el, index) => {
    // eslint-disable-next-line immutable/no-mutation
    branchCardRefs.current[index] = el;
  };

  return (
    <div>
      {isLoaded
    && (
    <div className="map-section-wrapper" id="our-branches-section">
      <div className={`map-section-container ${!branchesProp ? '' : 'no-top-margin'}`} id="our-branches-section">
        {
        !branchesProp && <p className="section-label">{t('mapSection.title')}</p>
        }
        <div className="map-data-container">
          <div className="branches-section">
            <div className="branches-filter-container">
              <div className="custom-select-wrapper">
                <CustomSelect
                  subLabel={mapState.selectedCountry === 'all' ? countryLabel : null}
                  value={mapState.selectedCountry}
                  options={branchesCountriesOptions}
                  setValue={countrySelectHandler}
                />
              </div>
              { mapState.selectedCountry !== 'all'
              && (
              <div className="custom-select-wrapper">
                <CustomSelect
                  subLabel={mapState.selectedCity === 'all' ? cityLabel : null}
                  value={mapState.selectedCity}
                  options={mapState.cityList}
                  setValue={citySelectHandler}
                />
              </div>
              )}
            </div>
            <div className="branches-container">
              {mapState.branchesData.map((branch, index) => (
                <BranchCard
                  index={index}
                  branch={branch}
                  handleButtonClick={handleButtonClick}
                  selectedLocation={mapState.selectedLocation}
                  branchRef={(e) => setBranchRef(e, index)}
                />
              ))}
            </div>
          </div>
          <div className="map-container">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={6}
              onLoad={handleMapLoad}
            >
              {mapState.branchesData.map((branch, index) => (
                <Marker key={index} position={branch} icon={mapState.selectedLocation === branch ? customMapIcon : undefined} onClick={(e) => handleMarkerClick(e, branch, index)} />
              ))}
            </GoogleMap>
          </div>
        </div>
      </div>
    </div>
    )}
    </div>
  );
};

export const BranchCard = ({
  index, handleButtonClick, branch, selectedLocation, branchRef,
}) => {
  const { i18n } = useTranslation('application');
  const label = i18n.language === 'en' ? branch.label.en : branch.label.ar;
  const address = i18n.language === 'en' ? branch.address.en : branch.address.ar;
  return (
    <button
      type="button"
      key={index}
      onClick={() => handleButtonClick(branch)}
      className={`branch-wrapper ${selectedLocation === branch && 'branch-selected'} ${i18n.language === 'en' ? 'branch-wrapper-border-en' : 'branch-wrapper-border-ar'}`}
      ref={branchRef}
    >
      <img src={branch.imgUrl} alt="camel step branch" className="branch-image" />
      <div className="branch-details">
        <p className="branch-name">{label}</p>
        <div className="branch-address-container">
          <div className="marker-container">
            <span className="marker-icon">
              <MarkerIcon className={`marker-icon-svg ${selectedLocation === branch && 'marker-selected'}`} />
            </span>
          </div>
          <div className="address-label-container">
            <a href={branch.branchLink} target="_blank" rel="noopener noreferrer">
              <p className="branch-address">{address}</p>
            </a>
          </div>
        </div>
      </div>
    </button>
  );
};
