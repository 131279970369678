import React from 'react';
import { Container } from './Container';
import { Navbar } from '../Navbar/Navbar';
import { Footer } from '../Footer/Footer';

export const MainLayout = ({ children, fluid, className }) => (
  <>
    <Navbar />
    <Container fluid={fluid} className={className}>{children}</Container>
    <Footer />
  </>
);
