import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GiftCodeInput } from '../../../components/inputs/Input/GiftCodeInput';
import { setDiscountCode, setOrderPrices, setPaymentData } from '../../../features/checkout/checkoutSlice';
import { useDebounce } from '../../../hooks/useDebounce';
import { useApplyDiscountCodeMutation } from '../../../services/order';

export const CheckoutGiftCode = () => {
  const currentStep = useSelector((state) => state.checkout.currentStep);
  const paymentData = useSelector((state) => state.checkout.paymentData);
  const discountCodeUsed = useSelector((state) => state.checkout.discountCode);
  const [giftCodeValue, setGiftCodeValue] = useState('');
  const [applyGiftCode, {
    data: discountCodeData, isLoading, error: discountCodeError, isSuccess, reset,
  }] = useApplyDiscountCodeMutation();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const debouncedGiftCodeValue = useDebounce(giftCodeValue, 2000);

  useEffect(() => {
    if (debouncedGiftCodeValue === '') {
      setError(false);
      return;
    }
    try {
      if (discountCodeUsed) return;
      applyGiftCode(debouncedGiftCodeValue).unwrap();
    } catch (e) {
      console.error(e);
    }
  }, [debouncedGiftCodeValue]);

  useEffect(() => {
    if (discountCodeError) {
      const { status, data: { error: discountCodeErr, errors } } = discountCodeError;
      if (status === 404) {
        setError([discountCodeErr]);
      } else if (status === 422) {
        const err = Object.values(errors);
        if (err.find((el) => el[0].startsWith('translation missing'))) return;
        setError(Object.values(errors));
      }
    }

    if (isSuccess) {
      dispatch(
        setOrderPrices({
          totalPrice: discountCodeData.new_total_price,
          vatPrice: discountCodeData.new_vat_price,
          summaryPrice: discountCodeData.new_summary_price,
          discountValue: discountCodeData.discount_value,
        }),
      );
      dispatch(setPaymentData({
        ...paymentData,
        amount: discountCodeData.new_summary_price,
      }));
      dispatch(setDiscountCode(giftCodeValue));
      setError(null);
    }
  }, [discountCodeError, isSuccess]);

  useEffect(() => {
    const code = discountCodeUsed || paymentData?.usedDiscountCode;
    if (code) {
      setGiftCodeValue(code);
      reset();
    }
  }, [discountCodeUsed, paymentData]);

  useEffect(() => {
    if ((!discountCodeUsed || currentStep !== 2) && !giftCodeValue) return;
    applyGiftCode(discountCodeUsed).unwrap();
  }, [discountCodeUsed, currentStep, giftCodeValue]);

  return (
    currentStep === 2 && (
      <GiftCodeInput
        isLoading={isLoading}
        onGiftCodeChange={setGiftCodeValue}
        error={error}
        success={isSuccess || discountCodeUsed}
        disabled={discountCodeUsed}
        value={giftCodeValue}
      />
    )
  );
};
