import React, { useState, useMemo } from 'react';

import * as Yup from 'yup';
import { Col, Row } from 'react-grid-system';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FormTextInput } from '../FormFields/FormTextInput';
import { CustomCheckbox } from '../inputs/CustomCheckbox/CustomCheckbox';
import { Button } from '../common/Button/Button';
import { useAddNewAddressMutation } from '../../services/order';
import { pickAddress } from '../../features/checkout/checkoutSlice';
import { eventBus } from '../../helpers/eventBus';
import { MapWrapper } from '../Map/MapWrapper';
import { FormSearchableSelect } from '../FormFields/FormSearchableSelect';

import { countriesList } from '../../shared/countrylist';

import './checkoutNewAddressForm.scss';
import { maxAddressLength } from '../Addresses/AddressForm/AddressForm';
import { zipCodeRegEx, fullNameRegex } from '../../shared/consts';

export const CheckoutNewAddressForm = ({ purpose }) => {
  const { t } = useTranslation('application');
  const [addAsBillingAddress, setAddAsBillingAddress] = useState(true);

  const [handleAddNewAddress] = useAddNewAddressMutation();

  const dispatch = useDispatch();

  const defaultCountry = useMemo(() => countriesList.find((country) => country.value === 'Saudi Arabia'), []);

  const formValidationSchema = Yup.object().shape({
    fullName: Yup.string().required(t('forms.fullName.requiredMsg')).min(3, t('forms.fullName.validationMsg.short')).matches(fullNameRegex, t('forms.fullName.validationMsg.specialCharacters')),
    phone: Yup.number().typeError(t('forms.phoneNumber.validationMsg')).required(t('forms.phoneNumber.requiredMsg')),
    email: Yup.string().email(t('forms.email.validationMsg')).required(t('forms.email.requiredMsg')),
    addressline1: Yup.string().required(t('forms.addressline1.requiredMsg')).max(maxAddressLength, t('addressline1.toLong')),
    addressline2: Yup.string().required(t('forms.addressline2.requiredMsg')).max(maxAddressLength, t('addressline2.toLong')),
    city: Yup.string().required(t('forms.city.requiredMsg')),
    country: Yup.string().required(t('forms.country.requiredMsg')),
    zipcode: Yup.string()
      .matches(zipCodeRegEx, t('forms.zip.validationMsg'))
      .required(t('forms.zip.requiredMsg')),
    purpose: Yup.string().required(t('forms.addressPurpose.requiredMsg')),
  });

  const submitForm = async (values, formikObj) => {
    const { setFieldError } = formikObj;
    const newAddress = {
      purpose: values.purpose,
      full_name: values.fullName,
      phone: values.phone,
      email: values.email,
      address_line_1: values.addressline1,
      address_line_2: values.addressline2,
      city: values.city,
      country: values.country,
      zipcode: values.zipcode,
    };
    const addressesToAdd = [newAddress, ...(addAsBillingAddress ? [{ ...newAddress, purpose: 'billing' }] : [])];
    const apiErrors = [];
    try {
      addressesToAdd.forEach(async (address, i) => {
        const resp = await handleAddNewAddress(address);
        if (resp?.error && typeof (setFieldError) === 'function') {
          const { errors } = resp.error.data;
          if (errors?.base) {
            setFieldError('root', t('forms.invalidAddress'));
          } else {
            Object.keys(errors).forEach((key) => {
              setFieldError(key, t(`forms.${key === 'zipcode' ? 'zip' : key}.validationMsg`));
            });
          }
          apiErrors.push(true);
          return;
        }
        apiErrors.push(false);
        dispatch(
          pickAddress({
            type: resp.purpose,
            address: resp.id,
          }),
        );
        if (i + 1 === addressesToAdd.length && !apiErrors.includes(true)) {
          eventBus.publish('modal:close');
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onCancel = () => {
    eventBus.publish('modal:close');
  };

  return (
    <div className="checkout-new-address">
      <MapWrapper position={[1, 1]} />
      {purpose === 'shipping' && (
        <CustomCheckbox
          checked={addAsBillingAddress}
          label={t('checkout.addAsBilling')}
          onChange={() => setAddAsBillingAddress((prevChecked) => !prevChecked)}
        />
      )}
      <Formik
        initialValues={{
          fullName: '',
          phone: '',
          email: '',
          addressline1: '',
          addressline2: '',
          city: '',
          country: defaultCountry.value,
          zipcode: '',
          purpose,
        }}
        validationSchema={formValidationSchema}
        onSubmit={(values, formikObj) => submitForm(values, formikObj)}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors }) => (
          <Form>
            <div>
              <FormTextInput type="text" name="fullName" label={t('forms.fullName.label')} />
              <FormTextInput type="text" name="phone" label={t('forms.phoneNumber.label')} />
              <FormTextInput type="text" name="email" label={t('forms.email.label')} />
              <div className={`fields-group ${errors?.root ? 'error' : ''}`}>
                <FormTextInput type="text" name="addressline1" label={t('forms.addressline1.label')} maxLength={maxAddressLength} />
                <FormTextInput type="text" name="addressline2" label={t('forms.addressline2.label')} maxLength={maxAddressLength} />
                <Row>
                  <Col xs={12} md={4}>
                    <FormTextInput type="text" name="city" label={t('forms.city.label')} />
                  </Col>
                  <Col xs={12} md={4}>
                    <FormSearchableSelect name="country" options={countriesList} placeholder={t('forms.country.label')} menuPlacement="top" />
                  </Col>
                  <Col xs={12} md={4}>
                    <FormTextInput type="text" name="zipcode" label={t('forms.zip.label')} />
                  </Col>
                </Row>
                {errors?.root && (
                  <Col xs={12}>
                    <p className="form__error-msg">{errors.root}</p>
                  </Col>
                )}
              </div>
            </div>
            <div className="form-actions">
              <Button
                className="form__submit-btn"
                type="primary"
                inverted
                onClick={() => onCancel()}
                text={t('forms.cancel')}
              />
              <Button className="form__submit-btn" type="primary" buttonType="submit" text={t('forms.addAddress')} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
