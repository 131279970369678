import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import { MainLayout } from '../../components/Layout/MainLayout';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { NotFoundPage } from '../404NotFound/NotFoundPage';
import { useGetSingleProductQuery } from '../../services/products';
import { SingleProduct } from './SingleProduct';

import './SingleProduct.scss';

export const ProductPage = () => {
  const { productId } = useParams();
  const {
    data, error, isFetching, refetch,
  } = useGetSingleProductQuery(productId);

  useEffect(() => {
    i18next.on('languageChanged', refetch);
    return () => {
      i18next.off('languageChanged', refetch);
    };
  }, []);

  if (isFetching) {
    return (
      <MainLayout>
        <Container className="single-product-container">
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (error) {
    return <NotFoundPage />;
  }

  return <SingleProduct product={data} productId={productId} />;
};
