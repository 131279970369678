import React from 'react';
import { Visible } from 'react-grid-system';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { SummaryItem } from '../../SummaryItem/SummaryItem';
import { CartItemActions } from '../CartItemActions';
import { useDeleteCartProductMutation } from '../../../services/cart';
import { ProductBadges } from '../../Product/ProductBadges';
import { Button } from '../../common/Button/Button';
import { SvgIcon } from '../../common/SvgIcon/SvgIcon';
import { CartItemBeanInfo } from './CartItemBeanInfo';
import { notify } from '../../../utils/notifications';
import { buildErrorMsg } from '../../../helpers/errorHelpers';
import { setCurrentStep } from '../../../features/checkout/checkoutSlice';

import './cartItem.scss';
import { PackageSummaryItem } from '../../PackageSummaryItem';

export const CartItem = ({ item, maxTotal }) => {
  const [handleProductDelete] = useDeleteCartProductMutation();
  const checkoutCurrentStep = useSelector((state) => state.checkout.currentStep);
  const dispatch = useDispatch();

  const handleRemoveProductFromCart = async (itemId) => {
    const {
      error: {
        data: { errors } = {},
      } = {},
    } = await handleProductDelete({ id: itemId });

    if (errors) {
      notify(buildErrorMsg(errors), 'warning');
    }

    if (checkoutCurrentStep === 3) {
      dispatch(setCurrentStep(1));
    }
  };
  return (
    <div className="cart-item">
      <Visible xs>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <ProductBadges badges={item.tags} />
          <Button
            type="iconButton"
            icon={<SvgIcon id="icon-delete" width={40} height={40} className="icon-delete" />}
            onClick={() => handleRemoveProductFromCart(item.id)}
          />
        </div>
      </Visible>
      {!_.isEmpty(item?.package_products)
        ? (
          <PackageSummaryItem
            name_en={item.name_en}
            name_ar={item.name_ar}
            image_url={item.image_url}
            quantity={item.quantity}
            totalQuantityPrice={item.total_quantity_price}
            vatPrice={item.vat_price}
            package_products={item.package_products}
          />
        ) : (
          <SummaryItem
            id={item.product_id}
            name={item.name}
            image_url={item.image_url}
            quantity={item.quantity}
            totalQuantityPrice={item.total_quantity_price}
            vatPrice={item.vat_price}
            variants={item.variants}
            modificators={item.modificators}
          />
        )}
      ;
      <div className="cart-item__bottom">
        <div className="cart-item__bottom-one">
          <CartItemBeanInfo productType={item.grouping} />
        </div>
        <div className="cart-item__bottom-two">
          <CartItemActions item={item} maxTotal={maxTotal} />
        </div>
      </div>
    </div>
  );
};
