import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-grid-system';
import { Outlet } from 'react-router-dom';
import { MainLayout } from '../../components/Layout/MainLayout';
import { Text } from '../../components/Text/Text';
import { ProfileNavButtons } from './ProfileNavButtons';

import './profilePage.scss';

export const ProfilePage = () => {
  const { t } = useTranslation('application');
  return (
    <MainLayout className="profile-page">
      <Container fluid style={{ paddingBottom: '10%' }}>
        <Row style={{ marginTop: '5%' }}>
          <Col>
            <Text type="headline2" style={{ color: '#00546F', marginBottom: 30 }}>
              {t('profile.greeting')}
            </Text>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col lg={3}>
            <ProfileNavButtons />
          </Col>
          <Col lg={9}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};
