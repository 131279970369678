import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import './Locations.scss';

SwiperCore.use([Navigation, Pagination]);

export const BranchCardLocationPage = ({ branch }) => {
  const { t, i18n } = useTranslation('application');
  const currentLanguage = i18n.language;

  const handleMapButtonClick = () => {
    window.open(branch.branchLink, '_blank');
  };

  return (
    <div className="branch-card-container">
      <div className="branch-card-image-container">
        <Swiper className="card-image" spaceBetween={10} slidesPerView={1} pagination={{ clickable: true }} navigation>
          {branch.images.map((image, index) => (
            <SwiperSlide key={index}>
              <img className="card-image" src={image} alt="Branch-cards" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="card-text-box">
        <p className="branch-card-title">{branch.label[currentLanguage]}</p>
        <p className="branch-card-address">
          {branch.address[currentLanguage]}
        </p>
        <p className="branch-card-timings">
          {`${t('locationsPage.branchCard.everyday')}: ${t(
            'locationsPage.branchCard.timing',
          )}`}
        </p>
        <button
          className="branch-card-map"
          type="button"
          onClick={handleMapButtonClick}
        >
          {t('locationsPage.branchCard.map')}
        </button>
      </div>
    </div>
  );
};
