import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswer } from '../../features/quiz/quizSlice';
import { ArrowButton } from '../common/Button/ArrowButton';
import { Text } from '../Text/Text';

export const QuizOptionsSwitcher = ({ question }) => {
  const { t, i18n } = useTranslation('application');
  const { language } = i18n;
  const dispatch = useDispatch();
  const answers = useSelector((state) => state.quiz.answers);
  const [height, setHeight] = useState(0);
  const switcherOptions = [`${t('quiz.selectOption')}`, ...question.choices];

  const findCurrentIndex = () => {
    const { param } = question;
    const currentAnswer = answers[param];
    const index = switcherOptions.map((el) => (el?.value || el)).indexOf(currentAnswer);
    if (index === -1) {
      return 0;
    }
    return index;
  };
  const [switcherIndex, setSwitcherIndex] = useState(findCurrentIndex() || 0);
  const [isTransitionEnabled, setTransitionEnabled] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [byButton, setByButton] = useState(false);

  const switcherRef = useRef();

  const getNested = (obj, ...args) => args.reduce((objct, level) => objct && objct[level], obj);

  const getSelected = (option) => {
    const answer = getNested(answers, question.param);
    if (answer === option) {
      return true;
    }
    return false;
  };

  const answer = getNested(answers, question.param);

  const handleArrowUpClick = () => {
    setByButton(true);
    if (!isTransitioning) {
      setSwitcherIndex((prevState) => prevState - 1);
    }
  };

  const handleArrowDownClick = () => {
    setByButton(true);
    if (!isTransitioning) {
      setSwitcherIndex((prevIndex) => prevIndex + 1);
    }
  };

  const extraPreviousItems = () => {
    const output = [];
    for (let index = 0; index < Math.ceil(switcherOptions.length / 2); index += 1) {
      const markup = (
        <div key={`prev${index}`} className="quiz-options__item">
          <Text type="headline3" style={{ padding: 0 }}>
            {switcherOptions[switcherOptions.length - 1 - index][language] || switcherOptions[switcherOptions.length - 1 - index]}
          </Text>
        </div>
      );
      output.push(markup);
    }
    output.reverse();
    return output;
  };

  const extraNextItems = () => {
    const output = [];
    for (let index = 0; index < Math.ceil(switcherOptions.length / 2); index += 1) {
      const markup = (
        <div key={`next${index}`} className="quiz-options__item">
          <Text type="headline3" style={{ padding: 0 }}>
            {switcherOptions[index][language] || switcherOptions[index]}
          </Text>
        </div>
      );
      output.push(markup);
    }
    return output;
  };

  const handleTransitionEnd = () => {
    if (switcherIndex > switcherOptions.length - 1) {
      setTransitionEnabled(false);
      setSwitcherIndex(switcherIndex - switcherOptions.length);
      const newAnswer = {
        param: question.param,
        answer: switcherOptions[switcherIndex - switcherOptions.length].value,
      };
      dispatch(setAnswer(newAnswer));
    } else if (switcherIndex < 0) {
      setTransitionEnabled(false);
      setSwitcherIndex(switcherOptions.length + switcherIndex);
      const newAnswer = {
        param: question.param,
        answer: switcherOptions[switcherOptions.length + switcherIndex].value,
      };
      dispatch(setAnswer(newAnswer));
    } else if (switcherIndex === 0) {
      setSwitcherIndex(0);
      const newAnswer = {
        param: question.param,
        answer: undefined,
      };
      dispatch(setAnswer(newAnswer));
    } else if (switcherIndex > 0 && switcherIndex < switcherOptions.length) {
      const newAnswer = {
        param: question.param,
        answer: switcherOptions[switcherIndex].value,
      };
      dispatch(setAnswer(newAnswer));
    }
    setIsTransitioning(false);
  };

  const moveByShortestPath = (currentIndex, targetIndex) => {
    const itemsLength = switcherOptions.length;
    let distanceUp; let
      distanceDown;

    if (targetIndex === 0) {
      distanceUp = itemsLength - currentIndex;
      distanceDown = currentIndex;
      if (distanceUp < distanceDown) {
        setSwitcherIndex((prevState) => prevState + distanceUp);
      } else {
        setSwitcherIndex((prevState) => prevState - distanceDown);
      }
    } else if (targetIndex > currentIndex) {
      distanceDown = targetIndex - currentIndex;
      distanceUp = switcherOptions.length - distanceDown;
      if (distanceDown < distanceUp) {
        setSwitcherIndex((prevState) => prevState + distanceDown);
      } else {
        setSwitcherIndex((prevState) => prevState - distanceUp);
      }
    } else {
      distanceUp = Math.abs(targetIndex - currentIndex);
      distanceDown = switcherOptions.length - Math.abs(distanceUp);
      if (distanceDown < distanceUp) {
        setSwitcherIndex((prevState) => prevState + distanceDown);
      } else {
        setSwitcherIndex((prevState) => prevState - distanceUp);
      }
    }
  };

  useEffect(() => {
    const currentIndex = switcherIndex;
    const targetIndex = switcherOptions.map((el) => (el?.value || el)).indexOf(answer);

    if (!byButton) {
      if (targetIndex === -1 && currentIndex === 0) {
        setSwitcherIndex(0);
      }
      if (targetIndex === -1 && currentIndex !== 0) {
        moveByShortestPath(currentIndex, 0);
      }
      if (targetIndex !== -1) {
        moveByShortestPath(currentIndex, targetIndex);
      }
    }
    setByButton(false);
  }, [answer]);

  useEffect(() => {
    switcherRef.current.addEventListener('transitionstart', () => {
      setIsTransitioning(true);
    });
  }, []);

  useEffect(() => {
    setTransitionEnabled(true);
  }, [switcherIndex]);

  const quizOptionRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <div className="quiz-options-wrapper" style={{ maxHeight: `${height ? height * 3 : 29}px` }}>
      <div className="quiz-options" style={{ top: `${height * -(Math.ceil(switcherOptions.length / 2) - 1)}px` }}>
        <div
          className="quiz-options-inner"
          style={{
            transform: `translateY(${switcherIndex * -height}px)`,
            transition: !isTransitionEnabled ? 'none' : undefined,
          }}
          onTransitionEnd={() => {
            handleTransitionEnd();
          }}
          ref={switcherRef}
        >
          {extraPreviousItems()}
          {switcherOptions.map((option, idx) => (
            <div
              key={idx}
              className={`quiz-options__item${getSelected(option?.value || option) ? ' selected' : ''} ${question.param === 'major_note' ? 'capitalize' : ''}`}
              data-value={option?.en || option}
              ref={idx === 0 ? quizOptionRef : null}
            >
              <Text type="headline3" style={{ padding: 0 }}>
                {option[language] || option}
              </Text>
            </div>
          ))}
          {extraNextItems()}
        </div>
      </div>
      <div className="quiz-options__switcher">
        <ArrowButton direction="up" onClick={() => handleArrowUpClick()} />
        <ArrowButton direction="down" onClick={() => handleArrowDownClick()} />
      </div>
    </div>
  );
};
