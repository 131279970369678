import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Alert } from '../../../../components/common/Alert/Alert';
import { Button } from '../../../../components/common/Button/Button';
import { FormTextInput } from '../../../../components/FormFields/FormTextInput';

export const NameForm = ({
  onSubmit, firstName, lastName, status,
}) => {
  const { t } = useTranslation('application', { keyPrefix: 'forms' });

  const formValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('firstName.required')),
    lastName: Yup.string().required(t('lastName.required')),
  });

  return (
    <Formik
      initialValues={{
        firstName: firstName || '',
        lastName: lastName || '',
      }}
      validationSchema={formValidationSchema}
      onSubmit={(values) => onSubmit(values)}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ dirty }) => (
        <Form>
          <FormTextInput name="firstName" label={t('firstName.label')} />
          <FormTextInput name="lastName" label={t('lastName.label')} />
          <Button className="form__submit-btn" type="primary" buttonType="submit" text={t('save')} disabled={!dirty} />
          {status.type !== '' && (
          <Alert type={status.type}>{status.message}</Alert>
          )}
        </Form>
      )}
    </Formik>
  );
};
