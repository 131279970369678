import React from 'react';

import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from '../../../../components/common/Button/Button';
import { FormTextInput } from '../../../../components/FormFields/FormTextInput';
import { Alert } from '../../../../components/common/Alert/Alert';

export const PhoneNumberChangeForm = ({ onSubmitPhoneNumber, initialPhoneNumber, error }) => {
  const { t } = useTranslation('application', { keyPrefix: 'forms' });

  const formValidationSchema = Yup.object().shape({
    phoneNumber: Yup.number()
      .typeError(t('phoneNumber.validationMsg'))
      .required(t('phoneNumber.requiredMsg')),
  });

  const onPhoneNumberChangeSubmit = (values) => {
    onSubmitPhoneNumber(values);
  };

  return (
    <Formik
      initialValues={{ phoneNumber: initialPhoneNumber || '' }}
      validationSchema={formValidationSchema}
      onSubmit={(values) => onPhoneNumberChangeSubmit(values)}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <FormTextInput type="tel" name="phoneNumber" label={t('phoneNumber.label')} />
          <Button className="form__submit-btn wide" type="primary" buttonType="submit" text={t('savePhoneNumber')} disabled={values.phoneNumber.trim() === initialPhoneNumber} />
          <div>
            {error && <Alert type="danger">{error}</Alert>}
          </div>
        </Form>
      )}
    </Formik>
  );
};
