import React from 'react';
import { useTranslation } from 'react-i18next';
import { MainLayout } from '../../components/Layout/MainLayout';
import { Text } from '../../components/Text/Text';

import './staticPage.scss';

export const AboutUs = () => {
  const { t } = useTranslation('application', { keyPrefix: 'aboutUs' });
  return (
    <MainLayout className="static_page about_us">
      <Text type="headline2">{t('title')}</Text>
      <Text type="body1">{t('p1')}</Text>
      <Text type="body1">{t('p2')}</Text>
      <Text type="headline3">{t('sub1')}</Text>
      <Text type="body1">{t('p3')}</Text>
      <Text type="headline3">{t('sub2')}</Text>
      <Text type="body1">{t('p4')}</Text>
      <Text type="headline3">{t('sub3')}</Text>
      <Text type="body1">{t('p5')}</Text>
      <Text type="headline3">{t('sub4')}</Text>
      <Text type="body1">{t('p6')}</Text>
    </MainLayout>
  );
};
