import camelStepKhobarBranch from '../assets/images/brachImages/camelStepKhobarBranch.jpg';
import camelStepKhobarBranch2 from '../assets/images/brachImages/camelStepKhobarBranch2.jpg';
import camelStepKhobarBranch3 from '../assets/images/brachImages/camelStepKhobarBranch3.jpg';
import camelStepAirportBranch from '../assets/images/brachImages/camelStepAirportBranch.jpg';
import camelStepAirportBranch2 from '../assets/images/brachImages/camelStepAirportBranch2.jpg';
import camelStepAirportBranch3 from '../assets/images/brachImages/camelStepAirportBranch3.jpg';
import camelStepAlUrubahBranch from '../assets/images/brachImages/camelStepAlUrubahBranch.jpg';
import camelStepAlUrubahBranch2 from '../assets/images/brachImages/camelStepAlUrubahBranch2.jpg';
import camelStepAlUrubahBranch3 from '../assets/images/brachImages/camelStepAlUrubahBranch3.jpg';
import camelStepAlTaawunBranch from '../assets/images/brachImages/camelStepAlTaawunBranch.jpg';
import camelStepAlTaawunBranch2 from '../assets/images/brachImages/camelStepAlTaawunBranch2.jpg';
import camelStepAlTaawunBranch3 from '../assets/images/brachImages/camelStepAlTaawunBranch3.jpg';
import camelStepMakkahBranch from '../assets/images/brachImages/camelStepMakkahBranch.jpg';
import camelStepMakkahBranch2 from '../assets/images/brachImages/camelStepMakkahBranch2.jpg';
import camelStepMakkahBranch3 from '../assets/images/brachImages/camelStepMakkahBranch3.jpg';
import camelStepAbhaBranch from '../assets/images/brachImages/camelStepAbhaBranch.jpg';
import camelStepAbhaBranch2 from '../assets/images/brachImages/camelStepAbhaBranch2.jpg';
import camelStepAbhaBranch3 from '../assets/images/brachImages/camelStepAbhaBranch3.jpg';
import camelStepJeddahBranch from '../assets/images/brachImages/camelStepJeddahBranch.jpg';
import camelStepJeddahBranch2 from '../assets/images/brachImages/camelStepJeddahBranch2.jpg';
import camelStepJeddahBranch3 from '../assets/images/brachImages/camelStepJeddahBranch3.jpg';
import camelStepDubaiBranch from '../assets/images/brachImages/camelStepDubaiBranch.jpg';
import camelStepDubaiBranch2 from '../assets/images/brachImages/camelStepDubaiBranch2.jpg';
import camelStepDubaiBranch3 from '../assets/images/brachImages/camelStepDubaiBranch3.jpg';
import camelStepHailBranch from '../assets/images/brachImages/camelStepHailBranch.jpg';

import CustomMarkerIcon from '../../public/blueMarker.png';

export const branchesCitiesOptions = [
  {
    id: 1,
    name: 'All',
    value: 'all',
    country: 'saudia, uae',
    translationName: {
      en: 'All',
      ar: 'الجميع',
    },
  },
  {
    id: 2,
    name: 'Abha',
    value: 'abha',
    country: 'saudia',
    translationName: {
      en: 'Abha',
      ar: 'أبها',
    },
  },
  {
    id: 3,
    name: 'Jeddah',
    value: 'jeddah',
    country: 'saudia',
    translationName: {
      en: 'Jeddah',
      ar: 'جدة',
    },
  },
  {
    id: 4,
    name: 'Khobar',
    value: 'khobar',
    country: 'saudia',
    translationName: {
      en: 'Khobar',
      ar: 'الخبر',
    },
  },
  {
    id: 5,
    name: 'Makkah',
    value: 'makkah',
    country: 'saudia',
    translationName: {
      en: 'Makkah',
      ar: 'مكة',
    },
  },
  {
    id: 6,
    name: 'Riyadh',
    value: 'riyadh',
    country: 'saudia',
    translationName: {
      en: 'Riyadh',
      ar: 'الرياض',
    },
  },
  {
    id: 7,
    name: 'Hail',
    value: 'hail',
    country: 'saudia',
    translationName: {
      en: 'Hail',
      ar: 'حائل',
    },
  },
  {
    id: 8,
    name: 'Dubai',
    value: 'dubai',
    country: 'uae',
    translationName: {
      en: 'Dubai',
      ar: 'دبي',
    },
  },
];

export const branchesCountriesOptions = [
  {
    id: 1,
    name: 'All',
    value: 'all',
  },
  {
    id: 2,
    name: 'Saudi Arabia',
    value: 'saudia',
    translationName: {
      en: 'Saudi Arabia',
      ar: 'السعودية',
    },
  },
  {
    id: 3,
    name: 'United Arab Emirates',
    value: 'uae',
    translationName: {
      en: 'United Arab Emirates',
      ar: 'الإمارات العربية المتحدة',
    },
  },
];

export const mapCenter = {
  lat: 23,
  lng: 47,
};

export const mapContainerStyle = {
  width: '100%',
  height: '57.7rem',
};

export const customMapIcon = {
  url: CustomMarkerIcon,
  scaledSize: { width: 40, height: 40 },
};

export const branchesLocation = [
  {
    lat: 26.326525477489362,
    lng: 50.21332662698655,
    label: {
      en: 'Camel Step Khobar Branch',
      ar: 'فرع خطوة الجمل الخبر',
    },
    address: {
      en: 'Salaheddin Al Ayoubi Street, Al Bustan, Al Khobar 34422, Saudi Arabia',
      ar: 'شارع صلاح الدين الأيوبي، البستان، الخبر 34422، المملكة العربية السعودية',
    },
    imgUrl: camelStepKhobarBranch,
    images: [
      camelStepKhobarBranch,
      camelStepKhobarBranch2,
      camelStepKhobarBranch3,
    ],
    city: 'khobar',
    country: 'saudia',
    branchLink: 'https://maps.app.goo.gl/KBMeiWUhKrxEivqEA',
  },
  {
    lat: 24.955576506533244,
    lng: 46.70552478465771,
    label: {
      en: 'Camel Step Riyadh Airport Branch',
      ar: 'خطوة الجمل فرع مطار الرياض',
    },
    address: {
      en: 'King Khalid International Airport, Riyadh 13449, Saudi Arabia',
      ar: 'مطار الملك خالد الدولي، الرياض 13449، المملكة العربية السعودية',
    },
    imgUrl: camelStepAirportBranch,
    images: [
      camelStepAirportBranch,
      camelStepAirportBranch2,
      camelStepAirportBranch3,
    ],
    city: 'riyadh',
    country: 'saudia',
    branchLink: 'https://maps.app.goo.gl/Jwa8ugopwq8fnik57',
  },
  {
    lat: 24.710681154928825,
    lng: 46.66762774232886,
    label: {
      en: 'Camel Step Riyadh Al Urubah Branch',
      ar: 'خطوة الجمل فرع الرياض العروبة',
    },
    address: {
      en: 'Al Urubah Rd, Ar Rahmaniyyah, Riyadh 12341, Saudi Arabia',
      ar: 'طريق العروبة، الرحمانية، الرياض 12341، المملكة العربية السعودية',
    },
    imgUrl: camelStepAlUrubahBranch,
    images: [
      camelStepAlUrubahBranch,
      camelStepAlUrubahBranch2,
      camelStepAlUrubahBranch3,
    ],
    city: 'riyadh',
    country: 'saudia',
    branchLink: 'https://maps.app.goo.gl/UN6GxpdJENmbBHc28',
  },
  {
    lat: 24.769530723196848,
    lng: 46.691048599999995,
    label: {
      en: 'Camel Step Riyadh Al Taawun Branch',
      ar: 'خطوة الجمل فرع الرياض التعاون',
    },
    address: {
      en: 'Abi Bakr As Siddiq Rd, حي التعاون, Riyadh 12475, Saudi Arabia',
      ar: 'طريق أبي بكر الصديق، حي التعاون، الرياض 12475، المملكة العربية السعودية',
    },
    imgUrl: camelStepAlTaawunBranch,
    images: [
      camelStepAlTaawunBranch,
      camelStepAlTaawunBranch2,
      camelStepAlTaawunBranch3,
    ],
    city: 'riyadh',
    country: 'saudia',
    branchLink: 'https://maps.app.goo.gl/Rsqyz837ScgHxuqi8',
  },
  {
    lat: 21.479907239992034,
    lng: 39.789047153103496,
    label: {
      en: 'Camel Step Makkah Al-Mukarrammah Branch',
      ar: 'خطوة الجمل فرع مكة المكرمة',
    },
    address: {
      en: 'C2 Jabal Omar, Ash Shubaikah, Ibrahim Al Khalil, Makkah 24231, Saudi Arabia',
      ar: 'C2 جبل عمر، الشبيكة، إبراهيم الخليل، مكة 24231، المملكة العربية السعودية',
    },
    imgUrl: camelStepMakkahBranch,
    images: [
      camelStepMakkahBranch,
      camelStepMakkahBranch2,
      camelStepMakkahBranch3,
    ],
    city: 'makkah',
    country: 'saudia',
    branchLink: 'https://maps.app.goo.gl/3n3NuYGJQn1DDv7R9',
  },
  {
    lat: 18.2104216000509,
    lng: 42.494301084657714,
    label: {
      en: 'Camel Step Abha Branch',
      ar: 'خطوة الجمل فرع أبها',
    },
    address: {
      en: 'King Saud Road, Al Muftaha, Abha 62521, Saudi Arabia',
      ar: 'طريق الملك سعود، المفتاحة، أبها 62521، المملكة العربية السعودية',
    },
    imgUrl: camelStepAbhaBranch,
    images: [
      camelStepAbhaBranch,
      camelStepAbhaBranch2,
      camelStepAbhaBranch3,
    ],
    city: 'abha',
    country: 'saudia',
    branchLink: 'https://maps.app.goo.gl/vXZ1DShzofRH856U7',
  },
  {
    lat: 21.816883069978594,
    lng: 39.142226999598655,
    label: {
      en: 'Camel Step Jeddah Branch',
      ar: 'خطوة الجمل فرع جدة',
    },
    address: {
      en: 'Abdulrahman aldakhel street, An Nahdah, Jeddah 23326, Saudi Arabia',
      ar: 'شارع عبدالرحمن الدخيل، النهضة، جدة 23326، المملكة العربية السعودية',
    },
    imgUrl: camelStepJeddahBranch,
    images: [
      camelStepJeddahBranch,
      camelStepJeddahBranch2,
      camelStepJeddahBranch3,
    ],
    city: 'jeddah',
    country: 'saudia',
    branchLink: 'https://maps.app.goo.gl/RmngymYGvyDreDgd9',
  },
  {
    lat: 25.36613256220703,
    lng: 55.25177397101405,
    label: {
      en: 'Camel Step Dubai Branch',
      ar: 'خطوة الجمل فرع دبي',
    },
    address: {
      en: '73B St - Al Satwa - Dubai - United Arab Emirates',
      ar: 'شارع 73ب - السطوة - دبي - الإمارات العربية المتحدة',
    },
    imgUrl: camelStepDubaiBranch,
    images: [
      camelStepDubaiBranch,
      camelStepDubaiBranch2,
      camelStepDubaiBranch3,
    ],
    city: 'dubai',
    country: 'uae',
    branchLink: 'https://maps.app.goo.gl/oLnjAKndQP15xEQu7',
  },
  {
    lat: 28.235442953101956,
    lng: 41.742747610029795,
    label: {
      en: 'Camel Step Hail Branch',
      ar: 'خطوة الجمل فرع حائل',
    },
    address: {
      en: '9495 البحيرة 31، حي البحيرة، HADE2725، 2725, Hail 55422, Saudi Arabia',
      ar: '9495 البحيرة 31، حي البحيرة، HADE2725، 2725, حائل 55422, المملكة العربية السعودية',
    },
    imgUrl: camelStepHailBranch,
    images: [
      camelStepHailBranch,
    ],
    city: 'hail',
    country: 'saudia',
    branchLink: 'https://maps.app.goo.gl/uTUTGVVdh5E4jxjL6',
  },
];
