import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { useGetProductsQuery } from '../../services/products';
import { Pagination } from '../Pagination/Pagination';
import { ProductItem } from '../ProductItem/ProductItem';
import { NoSearchResultFound } from '../NoSearchResultFound/NoSearchResultFound';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import './ProductList.scss';

const PRODUCTS_PER_PAGE = 16;

export const ProductsList = () => {
  const { t, i18n } = useTranslation('application');
  const [page, setPage] = useLocalStorage('paginationCurrentPage', 1);
  const [currentPage, setCurrentPage] = useState(page);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const activeFilters = useSelector((state) => state.products.activeFilters);
  const mainCategory = useSelector((state) => state.products.mainCategory);

  const { data, isLoading } = useGetProductsQuery({ currentPage, filterParams: appliedFilters, lang: i18n.language });
  const [currentProducts, setCurrentProducts] = useState([]);

  const productsref = useRef('');

  const parseActiveFilters = (filters, category) => {
    const allFilters = [];
    Object.keys(filters).forEach((filter) => {
      if (filters[filter].length !== 0) {
        if (filter === 'searchPhrase') {
          allFilters.push({ name: 'filters[q]', value: filters[filter] });
          return;
        }
        allFilters.push(...filters[filter]);
      }
    });
    if (category === 'Coffee') {
      allFilters.push({ name: 'filters[grouping]', value: 'coffee' });
    } else if (category === 'Tools') {
      allFilters.push({ name: 'filters[grouping]', value: 'equipment' });
    } else if (category === 'Archive') {
      allFilters.push({ name: 'filters[archived]', value: true });
    } else if (category === 'SpareParts') {
      allFilters.push({ name: 'filters[grouping]', value: 'spare_part' });
    }
    return allFilters;
  };

  useEffect(() => {
    const parsedFilters = parseActiveFilters(activeFilters, mainCategory);
    setAppliedFilters([...parsedFilters]);
  }, [activeFilters, mainCategory]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setCurrentProducts(data.records);

    if ((data.records / PRODUCTS_PER_PAGE) < currentPage) {
      setCurrentPage(1);
    }
  }, [data]);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    productsref.current.scrollIntoView({ behaviour: 'smooth', block: 'start' });
  };

  const returnProducts = () => {
    if (isLoading) {
      return <div>{t('common.loading')}</div>;
    }
    if (currentProducts.length === 0) {
      return <NoSearchResultFound />;
    }
    return (
      <div className="grid-container" ref={productsref}>
        {currentProducts.map((product) => (
          <ProductItem key={`${i18n.language}-${product.id}`} product={product} />
        ))}
      </div>
    );
  };

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div key={currentPage}>{returnProducts()}</motion.div>
      </AnimatePresence>

      {currentProducts.length !== 0 && (
        <Pagination
          postsPerPage={PRODUCTS_PER_PAGE}
          totalPosts={data?.total_count || 1}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </>
  );
};
