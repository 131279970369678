export const countriesList = [
  {
    value: 'Afghanistan',
    label: 'Afghanistan',
    translationName: { en: 'Afghanistan', ar: 'افغانستان' },
  },
  {
    value: 'Åland Islands',
    label: 'Åland Islands',
    translationName: { en: 'Åland Islands', ar: 'جزر آلاند' },
  },
  {
    value: 'Albania',
    label: 'Albania',
    translationName: { en: 'Albania', ar: 'ألبانيا' },
  },
  {
    value: 'Algeria',
    label: 'Algeria',
    translationName: { en: 'Algeria', ar: 'الجزائر' },
  },
  {
    value: 'American Samoa',
    label: 'American Samoa',
    translationName: { en: 'American Samoa', ar: 'ساموا الأمريكية' },
  },
  {
    value: 'Andorra',
    label: 'Andorra',
    translationName: { en: 'Andorra', ar: 'أندورا' },
  },
  {
    value: 'Angola',
    label: 'Angola',
    translationName: { en: 'Angola', ar: 'أنغولا' },
  },
  {
    value: 'Anguilla',
    label: 'Anguilla',
    translationName: { en: 'Anguilla', ar: 'أنغيلا' },
  },
  {
    value: 'Antarctica',
    label: 'Antarctica',
    translationName: { en: 'Antarctica', ar: 'أنتاركتيكا' },
  },
  {
    value: 'Antigua and Barbuda',
    label: 'Antigua and Barbuda',
    translationName: { en: 'Antigua and Barbuda', ar: 'أنتيغوا وبربودا' },
  },
  {
    value: 'Argentina',
    label: 'Argentina',
    translationName: { en: 'Argentina', ar: 'الأرجنتين' },
  },
  {
    value: 'Armenia',
    label: 'Armenia',
    translationName: { en: 'Armenia', ar: 'أرمينيا' },
  },
  {
    value: 'Aruba',
    label: 'Aruba',
    translationName: { en: 'Aruba', ar: 'أروبا' },
  },
  {
    value: 'Australia',
    label: 'Australia',
    translationName: { en: 'Australia', ar: 'أستراليا' },
  },
  {
    value: 'Austria',
    label: 'Austria',
    translationName: { en: 'Austria', ar: 'النمسا' },
  },
  {
    value: 'Azerbaijan',
    label: 'Azerbaijan',
    translationName: { en: 'Azerbaijan', ar: 'أذربيجان' },
  },
  {
    value: 'Bahamas',
    label: 'Bahamas',
    translationName: { en: 'Bahamas', ar: 'جزر بهاما' },
  },
  {
    value: 'Bahrain',
    label: 'Bahrain',
    translationName: { en: 'Bahrain', ar: 'البحرين' },
  },
  {
    value: 'Bangladesh',
    label: 'Bangladesh',
    translationName: { en: 'Bangladesh', ar: 'بنغلاديش' },
  },
  {
    value: 'Barbados',
    label: 'Barbados',
    translationName: { en: 'Barbados', ar: 'باربادوس' },
  },
  {
    value: 'Belarus',
    label: 'Belarus',
    translationName: { en: 'Belarus', ar: 'بيلاروسيا' },
  },
  {
    value: 'Belgium',
    label: 'Belgium',
    translationName: { en: 'Belgium', ar: 'بلجيكا' },
  },
  {
    value: 'Belize',
    label: 'Belize',
    translationName: { en: 'Belize', ar: 'بليز' },
  },
  {
    value: 'Benin',
    label: 'Benin',
    translationName: { en: 'Benin', ar: 'بنين' },
  },
  {
    value: 'Bermuda',
    label: 'Bermuda',
    translationName: { en: 'Bermuda', ar: 'برمودا' },
  },
  {
    value: 'Bhutan',
    label: 'Bhutan',
    translationName: { en: 'Bhutan', ar: 'بوتان' },
  },
  {
    value: 'Bolivia (Plurinational State of)',
    label: 'Bolivia',
    translationName: { en: 'Bolivia', ar: 'بوليفيا' },
  },
  {
    value: 'Bonaire, Sint Eustatius and Saba',
    label: 'Caribbean Netherlands',
    translationName: { en: 'Caribbean Netherlands', ar: 'جزر الكاريبي الهولندية' },
  },
  {
    value: 'Bosnia and Herzegovina',
    label: 'Bosnia and Herzegovina',
    translationName: { en: 'Bosnia and Herzegovina', ar: 'البوسنة والهرسك' },
  },
  {
    value: 'Botswana',
    label: 'Botswana',
    translationName: { en: 'Botswana', ar: 'بوتسوانا' },
  },
  {
    value: 'Bouvet Island',
    label: 'Bouvet Island',
    translationName: { en: 'Bouvet Island', ar: 'جزيرة بوفيت' },
  },
  {
    value: 'Brazil',
    label: 'Brazil',
    translationName: { en: 'Brazil', ar: 'البرازيل' },
  },
  {
    value: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
    translationName: {
      en: 'British Indian Ocean Territory',
      ar: 'إقليم المحيط الهندي البريطاني',
    },
  },
  {
    value: 'Brunei Darussalam',
    label: 'Brunei Darussalam',
    translationName: { en: 'Brunei Darussalam', ar: 'بروناي دار السلام' },
  },
  {
    value: 'Bulgaria',
    label: 'Bulgaria',
    translationName: { en: 'Bulgaria', ar: 'بلغاريا' },
  },
  {
    value: 'Burkina Faso',
    label: 'Burkina Faso',
    translationName: { en: 'Burkina Faso', ar: 'بوركينا فاسو ' },
  },
  {
    value: 'Burundi',
    label: 'Burundi',
    translationName: { en: 'Burundi', ar: 'بوروندي' },
  },
  {
    value: 'Cabo Verde',
    label: 'Cabo Verde',
    translationName: { en: 'Cabo Verde', ar: 'كابو فيردي' },
  },
  {
    value: 'Cambodia',
    label: 'Cambodia',
    translationName: { en: 'Cambodia', ar: 'كمبوديا' },
  },
  {
    value: 'Cameroon',
    label: 'Cameroon',
    translationName: { en: 'Cameroon', ar: 'الكاميرون' },
  },
  {
    value: 'Canada',
    label: 'Canada',
    translationName: { en: 'Canada', ar: 'كندا' },
  },
  {
    value: 'Cayman Islands',
    label: 'Cayman Islands',
    translationName: { en: 'Cayman Islands', ar: 'جزر كايمان ' },
  },
  {
    value: 'Chad',
    label: 'Chad',
    translationName: { en: 'Chad', ar: 'تشاد' },
  },
  {
    value: 'Chile',
    label: 'Chile',
    translationName: { en: 'Chile', ar: 'شيلي' },
  },
  {
    value: 'China',
    label: 'China',
    translationName: { en: 'China', ar: 'الصين' },
  },
  {
    value: 'Christmas Island',
    label: 'Christmas Island',
    translationName: { en: 'Christmas Island', ar: 'جزيرة كريسماس' },
  },
  {
    value: 'Cocos (Keeling) Islands',
    label: 'Cocos (Keeling) Islands',
    translationName: { en: 'Cocos (Keeling) Islands', ar: 'جزر كوكوس' },
  },
  {
    value: 'Colombia',
    label: 'Colombia',
    translationName: { en: 'Colombia', ar: 'كولومبيا' },
  },
  {
    value: 'Comoros',
    label: 'Comoros',
    translationName: { en: 'Comoros', ar: 'جزر القمر' },
  },
  {
    value: 'Cocos (Keeling) Islands',
    label: 'Cocos (Keeling) Islands',
    translationName: { en: 'Cocos (Keeling) Islands', ar: 'جزر كوكوس' },
  },
  {
    value: 'Colombia',
    label: 'Colombia',
    translationName: { en: 'Colombia', ar: 'كولومبيا' },
  },
  {
    value: 'Comoros',
    label: 'Comoros',
    translationName: { en: 'Comoros', ar: 'جزر القمر' },
  },
  {
    value: 'Cook Islands',
    label: 'Cook Islands',
    translationName: { en: 'Cook Islands', ar: 'جزر كوك' },
  },
  {
    value: 'Costa Rica',
    label: 'Costa Rica',
    translationName: { en: 'Costa Rica', ar: 'كوستاريكا' },
  },
  {
    value: 'Côte d\'Ivoire',
    label: 'Ivory Coast',
    translationName: { en: 'Ivory Coast', ar: 'ساحل العاج' },
  },
  {
    value: 'Croatia',
    label: 'Croatia',
    translationName: { en: 'Croatia', ar: 'كرواتيا' },
  },
  {
    value: 'Cuba',
    label: 'Cuba',
    translationName: { en: 'Cuba', ar: 'كوبا' },
  },
  {
    value: 'Curaçao',
    label: 'Curaçao',
    translationName: { en: 'Curaçao', ar: 'كوراساو' },
  },
  {
    value: 'Cyprus',
    label: 'Cyprus',
    translationName: { en: 'Cyprus', ar: 'قبرص' },
  },
  {
    value: 'Czechia',
    label: 'Czech Republic',
    translationName: { en: 'Czech Republic', ar: 'الجمهورية التشيكية' },
  },
  {
    value: 'Denmark',
    label: 'Denmark',
    translationName: { en: 'Denmark', ar: 'الدنمارك ' },
  },
  {
    value: 'Djibouti',
    label: 'Djibouti',
    translationName: { en: 'Djibouti', ar: 'جيبوتي' },
  },
  {
    value: 'Dominica',
    label: 'Dominica',
    translationName: { en: 'Dominica', ar: 'دومينيكا' },
  },
  {
    value: 'Dominican Republic',
    label: 'Dominican Republic',
    translationName: { en: 'Dominican Republic', ar: 'جمهورية الدمينيكان' },
  },
  {
    value: 'Ecuador',
    label: 'Ecuador',
    translationName: { en: 'Ecuador', ar: 'إكوادور' },
  },
  {
    value: 'Egypt',
    label: 'Egypt',
    translationName: { en: 'Egypt', ar: 'مصر' },
  },
  {
    value: 'El Salvador',
    label: 'El Salvador',
    translationName: { en: 'El Salvador', ar: 'السلفادور' },
  },
  {
    value: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
    translationName: { en: 'Equatorial Guinea', ar: 'غينيا الاستوائية' },
  },
  {
    value: 'Estonia',
    label: 'Estonia',
    translationName: { en: 'Estonia', ar: 'إستونيا' },
  },
  {
    value: 'Swaziland',
    label: 'Eswatini',
    translationName: { en: 'Eswatini', ar: 'إيسواتيني' },
  },
  {
    value: 'Ethiopia',
    label: 'Ethiopia',
    translationName: { en: 'Ethiopia', ar: 'إثيوبيا' },
  },
  {
    value: 'Falkland Islands (Malvinas)',
    label: 'Falkland Islands',
    translationName: { en: 'Falkland Islands', ar: 'جزر فوكلاند' },
  },
  {
    value: 'Faroe Islands',
    label: 'Faroe Islands',
    translationName: { en: 'Faroe Islands', ar: 'جزر فارو' },
  },
  {
    value: 'Fiji',
    label: 'Fiji',
    translationName: { en: 'Fiji', ar: 'فيجي' },
  },
  {
    value: 'Finland',
    label: 'Finland',
    translationName: { en: 'Finland', ar: 'فنلندا' },
  },
  {
    value: 'France',
    label: 'France',
    translationName: { en: 'France', ar: 'فرنسا' },
  },
  {
    value: 'French Guiana',
    label: 'French Guiana',
    translationName: { en: 'French Guiana', ar: 'غيانا الفرنسية' },
  },
  {
    value: 'French Polynesia',
    label: 'French Polynesia',
    translationName: { en: 'French Polynesia', ar: 'بولينريا الفرنسية' },
  },
  {
    value: 'French Southern Territories',
    label: 'French Southern and Antarctic Lands',
    translationName: {
      en: 'French Southern and Antarctic Lands',
      ar: 'الجنوب الفرنسي والأراضي القطبية الجنوبية',
    },
  },
  {
    value: 'Gabon',
    label: 'Gabon',
    translationName: { en: 'Gabon', ar: 'غابون' },
  },
  {
    value: 'Gambia',
    label: 'Gambia',
    translationName: { en: 'Gambia', ar: 'غامبيا' },
  },
  {
    value: 'Georgia',
    label: 'Georgia (country)',
    translationName: { en: 'Georgia (country)', ar: 'جورجيا' },
  },
  {
    value: 'Germany',
    label: 'Germany',
    translationName: { en: 'Germany', ar: 'ألمانيا' },
  },
  {
    value: 'Ghana',
    label: 'Ghana',
    translationName: { en: 'Ghana', ar: 'غانا' },
  },
  {
    value: 'Gibraltar',
    label: 'Gibraltar',
    translationName: { en: 'Gibraltar', ar: 'جبل طارق' },
  },
  {
    value: 'Greece',
    label: 'Greece',
    translationName: { en: 'Greece', ar: 'اليونان' },
  },
  {
    value: 'Greenland',
    label: 'Greenland',
    translationName: { en: 'Greenland', ar: 'جرينلاند' },
  },
  {
    value: 'Grenada',
    label: 'Grenada',
    translationName: { en: 'Grenada', ar: 'غرينادا' },
  },
  {
    value: 'Guadeloupe',
    label: 'Guadeloupe',
    translationName: { en: 'Guadeloupe', ar: 'جوادلوب' },
  },
  {
    value: 'Guam',
    label: 'Guam',
    translationName: { en: 'Guam', ar: 'غوام' },
  },
  {
    value: 'Guatemala',
    label: 'Guatemala',
    translationName: { en: 'Guatemala', ar: 'غواتيمالا' },
  },
  {
    value: 'Guernsey',
    label: 'Bailiwick of Guernsey',
    translationName: { en: 'Bailiwick of Guernsey', ar: 'بيليويك غيرنسي' },
  },
  {
    value: 'Guinea',
    label: 'Guinea',
    translationName: { en: 'Guinea', ar: 'غينيا' },
  },
  {
    value: 'Guinea-Bissau',
    label: 'Guinea-Bissau',
    translationName: { en: 'Guinea-Bissau', ar: 'غينيا بيساو' },
  },
  {
    value: 'Guyana',
    label: 'Guyana',
    translationName: { en: 'Guyana', ar: 'غيانا' },
  },
  {
    value: 'Haiti',
    label: 'Haiti',
    translationName: { en: 'Haiti', ar: 'هايتي' },
  },
  {
    value: 'Heard Island and McDonald Islands',
    label: 'Heard Island and McDonald Islands',
    translationName: {
      en: 'Heard Island and McDonald Islands',
      ar: 'قلب الجزيرة وجزر ماكدونالز',
    },
  },
  {
    value: 'Holy See',
    label: 'Vatican City',
    translationName: { en: 'Vatican City', ar: 'مدينة الفاتيكان' },
  },
  {
    value: 'Honduras',
    label: 'Honduras',
    translationName: { en: 'Honduras', ar: 'هندوراس' },
  },
  {
    value: 'Hong Kong',
    label: 'Hong Kong',
    translationName: { en: 'Hong Kong', ar: 'هونغ كونغ' },
  },
  {
    value: 'Hungary',
    label: 'Hungary',
    translationName: { en: 'Hungary', ar: 'المجر' },
  },
  {
    value: 'Iceland',
    label: 'Iceland',
    translationName: { en: 'Iceland', ar: 'أيسلندا' },
  },
  {
    value: 'India',
    label: 'India',
    translationName: { en: 'India', ar: 'الهند' },
  },
  {
    value: 'Indonesia',
    label: 'Indonesia',
    translationName: { en: 'Indonesia', ar: 'اندونيسيا' },
  },
  {
    value: 'Iraq',
    label: 'Iraq',
    translationName: { en: 'Iraq', ar: 'العراق' },
  },
  {
    value: 'Ireland',
    label: 'Republic of Ireland',
    translationName: { en: 'Republic of Ireland', ar: 'جمهورية أيرلندا' },
  },
  {
    value: 'Isle of Man',
    label: 'Isle of Man',
    translationName: { en: 'Isle of Man', ar: 'جزيرة مان' },
  },
  {
    value: 'Italy',
    label: 'Italy',
    translationName: { en: 'Italy', ar: 'إيطاليا' },
  },
  {
    value: 'Jamaica',
    label: 'Jamaica',
    translationName: { en: 'Jamaica', ar: 'جامايكا' },
  },
  {
    value: 'Japan',
    label: 'Japan',
    translationName: { en: 'Japan', ar: 'اليابان' },
  },
  {
    value: 'Jersey',
    label: 'Jersey',
    translationName: { en: 'Jersey', ar: 'جيرسي' },
  },
  {
    value: 'Jordan',
    label: 'Jordan',
    translationName: { en: 'Jordan', ar: 'الأردن' },
  },
  {
    value: 'Kazakhstan',
    label: 'Kazakhstan',
    translationName: { en: 'Kazakhstan', ar: 'كازاخستان' },
  },
  {
    value: 'Kenya',
    label: 'Kenya',
    translationName: { en: 'Kenya', ar: 'كينيا' },
  },
  {
    value: 'Kiribati',
    label: 'Kiribati',
    translationName: { en: 'Kiribati', ar: 'كيريباتي' },
  },
  {
    value: 'Korea (Republic of)',
    label: 'South Korea',
    translationName: { en: 'South Korea', ar: 'كوريا الجنوبية' },
  },
  {
    value: 'Kuwait',
    label: 'Kuwait',
    translationName: { en: 'Kuwait', ar: 'الكويت' },
  },
  {
    value: 'Kyrgyzstan',
    label: 'Kyrgyzstan',
    translationName: { en: 'Kyrgyzstan', ar: 'قيرغيزستان' },
  },
  {
    value: 'Lao People\'s Democratic Republic',
    label: 'Laos',
    translationName: { en: 'Laos', ar: 'لاوس' },
  },
  {
    value: 'Latvia',
    label: 'Latvia',
    translationName: { en: 'Latvia', ar: 'لاتفيا' },
  },
  {
    value: 'Lebanon',
    label: 'Lebanon',
    translationName: { en: 'Lebanon', ar: 'لبنان' },
  },
  {
    value: 'Lesotho',
    label: 'Lesotho',
    translationName: { en: 'Lesotho', ar: 'ليسوتو' },
  },
  {
    value: 'Liberia',
    label: 'Liberia',
    translationName: { en: 'Liberia', ar: 'ليبيريا' },
  },
  {
    value: 'Libya',
    label: 'Libya',
    translationName: { en: 'Libya', ar: 'ليبيا' },
  },
  {
    value: 'Liechtenstein',
    label: 'Liechtenstein',
    translationName: { en: 'Liechtenstein', ar: 'ليختنشتاين' },
  },
  {
    value: 'Lithuania',
    label: 'Lithuania',
    translationName: { en: 'Lithuania', ar: 'ليتوانيا' },
  },
  {
    value: 'Luxembourg',
    label: 'Luxembourg',
    translationName: { en: 'Luxembourg', ar: 'لوكسمبورغ' },
  },
  {
    value: 'Macao',
    label: 'Macau',
    translationName: { en: 'Macau', ar: 'ماكاو' },
  },
  {
    value: 'Madagascar',
    label: 'Madagascar',
    translationName: { en: 'Madagascar', ar: 'مدغشقر' },
  },
  {
    value: 'Malawi',
    label: 'Malawi',
    translationName: { en: 'Malawi', ar: 'مالاوي' },
  },
  {
    value: 'Malaysia',
    label: 'Malaysia',
    translationName: { en: 'Malaysia', ar: 'ماليزيا' },
  },
  {
    value: 'Maldives',
    label: 'Maldives',
    translationName: { en: 'Maldives', ar: 'المالديف' },
  },
  {
    value: 'Mali',
    label: 'Mali',
    translationName: { en: 'Mali', ar: 'مالي' },
  },
  {
    value: 'Malta',
    label: 'Malta',
    translationName: { en: 'Malta', ar: 'مالطا' },
  },
  {
    value: 'Marshall Islands',
    label: 'Marshall Islands',
    translationName: { en: 'Marshall Islands', ar: 'جزر مارشال' },
  },
  {
    value: 'Martinique',
    label: 'Martinique',
    translationName: { en: 'Martinique', ar: 'مارتينيك' },
  },
  {
    value: 'Mauritania',
    label: 'Mauritania',
    translationName: { en: 'Mauritania', ar: 'مورتانيا' },
  },
  {
    value: 'Mauritius',
    label: 'Mauritius',
    translationName: { en: 'Mauritius', ar: 'موريشيوس' },
  },
  {
    value: 'Mayotte',
    label: 'Mayotte',
    translationName: { en: 'Mayotte', ar: 'مايوت' },
  },
  {
    value: 'Mexico',
    label: 'Mexico',
    translationName: { en: 'Mexico', ar: 'المكسيك' },
  },
  {
    value: 'Micronesia (Federated States of)',
    label: 'Federated States of Micronesia',
    translationName: {
      en: 'Federated States of Micronesia',
      ar: 'ولايات ميكرونيزيا الموحدة ',
    },
  },
  {
    value: 'Moldova (Republic of)',
    label: 'Moldova',
    translationName: { en: 'Moldova', ar: 'مولدوفا' },
  },
  {
    value: 'Monaco',
    label: 'Monaco',
    translationName: { en: 'Monaco', ar: 'موناكو' },
  },
  {
    value: 'Mongolia',
    label: 'Mongolia',
    translationName: { en: 'Mongolia', ar: 'منغوليا' },
  },
  {
    value: 'Montenegro',
    label: 'Montenegro',
    translationName: { en: 'Montenegro', ar: 'الجبل الأسود' },
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
    translationName: { en: 'Montserrat', ar: 'مونتسيرات' },
  },
  {
    value: 'Morocco',
    label: 'Morocco',
    translationName: { en: 'Morocco', ar: 'المغرب' },
  },
  {
    value: 'Mozambique',
    label: 'Mozambique',
    translationName: { en: 'Mozambique', ar: 'موزامبيق' },
  },
  {
    value: 'Myanmar',
    label: 'Myanmar',
    translationName: { en: 'Myanmar', ar: 'ميانمار' },
  },
  {
    value: 'Namibia',
    label: 'Namibia',
    translationName: { en: 'Namibia', ar: 'ناميبيا' },
  },
  {
    value: 'Nauru',
    label: 'Nauru',
    translationName: { en: 'Nauru', ar: 'ناورو' },
  },
  {
    value: 'Nepal',
    label: 'Nepal',
    translationName: { en: 'Nepal', ar: 'النيبال' },
  },
  {
    value: 'Netherlands',
    label: 'Kingdom of the Netherlands',
    translationName: { en: 'Kingdom of the Netherlands', ar: 'مملكة هولندا' },
  },
  {
    value: 'New Caledonia',
    label: 'New Caledonia',
    translationName: { en: 'New Caledonia', ar: 'كاليدونيا الجديدة' },
  },
  {
    value: 'New Zealand',
    label: 'New Zealand',
    translationName: { en: 'New Zealand', ar: 'نيوزلندا' },
  },
  {
    value: 'Nicaragua',
    label: 'Nicaragua',
    translationName: { en: 'Nicaragua', ar: 'نيكاراغوا' },
  },
  {
    value: 'Niger',
    label: 'Niger',
    translationName: { en: 'Niger', ar: 'النيجر' },
  },
  {
    value: 'Nigeria',
    label: 'Nigeria',
    translationName: { en: 'Nigeria', ar: 'نيجيريا' },
  },
  {
    value: 'Niue',
    label: 'Niue',
    translationName: { en: 'Niue', ar: 'نيوي' },
  },
  {
    value: 'Norfolk Island',
    label: 'Norfolk Island',
    translationName: { en: 'Norfolk Island', ar: ',جزيرة نورفولك' },
  },
  {
    value: 'Macedonia (the former Yugoslav Republic of)',
    label: 'North Macedonia',
    translationName: { en: 'North Macedonia', ar: 'مقدونيا الشمالية' },
  },
  {
    value: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
    translationName: { en: 'Northern Mariana Islands', ar: 'جزر ماريانا الشمالية' },
  },
  {
    value: 'Norway',
    label: 'Norway',
    translationName: { en: 'Norway', ar: 'النرويج' },
  },
  {
    value: 'Oman',
    label: 'Oman',
    translationName: { en: 'Oman', ar: 'عمان' },
  },
  {
    value: 'Pakistan',
    label: 'Pakistan',
    translationName: { en: 'Pakistan', ar: 'باكستان' },
  },
  {
    value: 'Palau',
    label: 'Palau',
    translationName: { en: 'Palau', ar: 'بالاو' },
  },
  {
    value: 'Palestine, State of',
    label: 'State of Palestine',
    translationName: { en: 'State of Palestine', ar: 'دولة فلسطين' },
  },
  {
    value: 'Panama',
    label: 'Panama',
    translationName: { en: 'Panama', ar: 'بنما' },
  },
  {
    value: 'Papua New Guinea',
    label: 'Papua New Guinea',
    translationName: { en: 'Papua New Guinea', ar: 'بابوا غينيا الجديدة' },
  },
  {
    value: 'Paraguay',
    label: 'Paraguay',
    translationName: { en: 'Paraguay', ar: 'باراغواي' },
  },
  {
    value: 'Peru',
    label: 'Peru',
    translationName: { en: 'Peru', ar: 'بيرو' },
  },
  {
    value: 'Philippines',
    label: 'Philippines',
    translationName: { en: 'Philippines', ar: 'الفلبين' },
  },
  {
    value: 'Pitcairn',
    label: 'Pitcairn Islands',
    translationName: { en: 'Pitcairn Islands', ar: 'جزر بيتكيرن' },
  },
  {
    value: 'Poland',
    label: 'Poland',
    translationName: { en: 'Poland', ar: 'بولندا' },
  },
  {
    value: 'Portugal',
    label: 'Portugal',
    translationName: { en: 'Portugal', ar: 'البرتغال' },
  },
  {
    value: 'Puerto Rico',
    label: 'Puerto Rico',
    translationName: { en: 'Puerto Rico', ar: 'بورتوريكو' },
  },
  {
    value: 'Qatar',
    label: 'Qatar',
    translationName: { en: 'Qatar', ar: 'قطر' },
  },
  {
    value: 'Réunion',
    label: 'Réunion',
    translationName: { en: 'Réunion', ar: 'Réunion' },
  },
  {
    value: 'Romania',
    label: 'Romania',
    translationName: { en: 'Romania', ar: 'رومانيا' },
  },
  {
    value: 'Rwanda',
    label: 'Rwanda',
    translationName: { en: 'Rwanda', ar: 'رواندا' },
  },
  {
    value: 'Saint Barthélemy',
    label: 'Saint Barthélemy',
    translationName: { en: 'Saint Barthélemy', ar: 'سانت بارتيليمي' },
  },
  {
    value: 'Saint Helena, Ascension and Tristan da Cunha',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    translationName: {
      en: 'Saint Helena, Ascension and Tristan da Cunha',
      ar: 'سانت هيلانة وأسنسيون وتريستان دا كونها',
    },
  },
  {
    value: 'Saint Kitts and Nevis',
    label: 'Saint Kitts and Nevis',
    translationName: { en: 'Saint Kitts and Nevis', ar: 'سانت كيتس ونيفيس' },
  },
  {
    value: 'Saint Lucia',
    label: 'Saint Lucia',
    translationName: { en: 'Saint Lucia', ar: 'سانت لوسيا' },
  },
  {
    value: 'Saint Martin (French part)',
    label: 'Collectivity of Saint Martin',
    translationName: { en: 'Collectivity of Saint Martin', ar: 'جماعة سانت مارتن' },
  },
  {
    value: 'Saint Pierre and Miquelon',
    label: 'Saint Pierre and Miquelon',
    translationName: { en: 'Saint Pierre and Miquelon', ar: 'سانت بيير وميكلون' },
  },
  {
    value: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
    translationName: {
      en: 'Saint Vincent and the Grenadines',
      ar: 'سانت فنسنت وجزر غرينادين',
    },
  },
  {
    value: 'Samoa',
    label: 'Samoa',
    translationName: { en: 'Samoa', ar: 'ساموا' },
  },
  {
    value: 'San Marino',
    label: 'San Marino',
    translationName: { en: 'San Marino', ar: 'سان مارينو' },
  },
  {
    value: 'Sao Tome and Principe',
    label: 'São Tomé and Príncipe',
    translationName: { en: 'São Tomé and Príncipe', ar: 'ساو تومي وبرينسيبي' },
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia',
    translationName: { en: 'Saudi Arabia', ar: 'المملكة العربية السعودية' },
  },
  {
    value: 'Senegal',
    label: 'Senegal',
    translationName: { en: 'Senegal', ar: 'السنغال' },
  },
  {
    value: 'Serbia',
    label: 'Serbia',
    translationName: { en: 'Serbia', ar: 'صربيا' },
  },
  {
    value: 'Seychelles',
    label: 'Seychelles',
    translationName: { en: 'Seychelles', ar: 'سيشيل' },
  },
  {
    value: 'Sierra Leone',
    label: 'Sierra Leone',
    translationName: { en: 'Sierra Leone', ar: 'سيراليون' },
  },
  {
    value: 'Singapore',
    label: 'Singapore',
    translationName: { en: 'Singapore', ar: 'سنغافورة' },
  },
  {
    value: 'Sint Maarten (Dutch part)',
    label: 'Sint Maarten',
    translationName: { en: 'Sint Maarten', ar: 'سينت مارتن' },
  },
  {
    value: 'Slovakia',
    label: 'Slovakia',
    translationName: { en: 'Slovakia', ar: 'سلوفاكيا' },
  },
  {
    value: 'Slovenia',
    label: 'Slovenia',
    translationName: { en: 'Slovenia', ar: 'سلوفينيا' },
  },
  {
    value: 'Solomon Islands',
    label: 'Solomon Islands',
    translationName: { en: 'Solomon Islands', ar: 'جزر سليمان' },
  },
  {
    value: 'Somalia',
    label: 'Somalia',
    translationName: { en: 'Somalia', ar: 'الصومال' },
  },
  {
    value: 'South Africa',
    label: 'South Africa',
    translationName: { en: 'South Africa', ar: 'جنوب أفريقيا' },
  },
  {
    value: 'South Georgia and the South Sandwich Islands',
    label: 'South Georgia and the South Sandwich Islands',
    translationName: {
      en: 'South Georgia and the South Sandwich Islands',
      ar: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    },
  },
  {
    value: 'Spain',
    label: 'Spain',
    translationName: { en: 'Spain', ar: 'أسبانيا' },
  },
  {
    value: 'Sri Lanka',
    label: 'Sri Lanka',
    translationName: { en: 'Sri Lanka', ar: 'سيريلانكا' },
  },
  {
    value: 'Suriname',
    label: 'Suriname',
    translationName: { en: 'Surivalue', ar: 'Surivalue' },
  },
  {
    value: 'Svalbard and Jan Mayen',
    label: 'Svalbard and Jan Mayen',
    translationName: { en: 'Svalbard and Jan Mayen', ar: 'سفالبارد وجان ماين' },
  },
  {
    value: 'Sweden',
    label: 'Sweden',
    translationName: { en: 'Sweden', ar: 'السويد' },
  },
  {
    value: 'Switzerland',
    label: 'Switzerland',
    translationName: { en: 'Switzerland', ar: 'سويسرا' },
  },
  {
    value: 'Syrian Arab Republic',
    label: 'Syria',
    translationName: { en: 'Syria', ar: 'سوريا' },
  },
  {
    value: 'Taiwan',
    label: 'Taiwan, China',
    translationName: { en: 'Taiwan, China', ar: 'الصين تايوان' },
  },
  {
    value: 'Tajikistan',
    label: 'Tajikistan',
    translationName: { en: 'Tajikistan', ar: 'طاجيكستان' },
  },
  {
    value: 'Tanzania, United Republic of',
    label: 'Tanzania',
    translationName: { en: 'Tanzania', ar: 'تنزانيا' },
  },
  {
    value: 'Thailand',
    label: 'Thailand',
    translationName: { en: 'Thailand', ar: 'تايلاند' },
  },
  {
    value: 'Timor-Leste',
    label: 'East Timor',
    translationName: { en: 'East Timor', ar: 'تيمور الشرقية' },
  },
  {
    value: 'Togo',
    label: 'Togo',
    translationName: { en: 'Togo', ar: 'توغو' },
  },
  {
    value: 'Tokelau',
    label: 'Tokelau',
    translationName: { en: 'Tokelau', ar: 'توكيلاو' },
  },
  {
    value: 'Tonga',
    label: 'Tonga',
    translationName: { en: 'Tonga', ar: 'تونغا' },
  },
  {
    value: 'Trinidad and Tobago',
    label: 'Trinidad and Tobago',
    translationName: { en: 'Trinidad and Tobago', ar: 'ترينداد وتوباغو' },
  },
  {
    value: 'Tunisia',
    label: 'Tunisia',
    translationName: { en: 'Tunisia', ar: 'تونس' },
  },
  {
    value: 'Turkey',
    label: 'Turkey',
    translationName: { en: 'Turkey', ar: 'تركيا' },
  },
  {
    value: 'Turkmenistan',
    label: 'Turkmenistan',
    translationName: { en: 'Turkmenistan', ar: 'تركمانستان' },
  },
  {
    value: 'Turks and Caicos Islands',
    label: 'Turks and Caicos Islands',
    translationName: { en: 'Turks and Caicos Islands', ar: 'جزر تركس وكايكوس' },
  },
  {
    value: 'Tuvalu',
    label: 'Tuvalu',
    translationName: { en: 'Tuvalu', ar: 'توفالو' },
  },
  {
    value: 'Uganda',
    label: 'Uganda',
    translationName: { en: 'Uganda', ar: 'أوغندا' },
  },
  {
    value: 'Ukraine',
    label: 'Ukraine',
    translationName: { en: 'Ukraine', ar: 'أوكرانيا' },
  },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
    translationName: { en: 'United Arab Emirates', ar: 'الإمارات العربية المتحدة' },
  },
  {
    value: 'United Kingdom of Great Britain and Northern Ireland',
    label: 'United Kingdom',
    translationName: { en: 'United Kingdom', ar: 'المملكة المتحدة' },
  },
  {
    value: 'United States of America',
    label: 'United States',
    translationName: { en: 'United States', ar: 'الولايات المتحدة' },
  },
  {
    value: 'United States Minor Outlying Islands',
    label: 'United States Minor Outlying Islands',
    translationName: {
      en: 'United States Minor Outlying Islands',
      ar: 'جزر الولايات المتحدة البعيدة الصغرى',
    },
  },
  {
    value: 'Uruguay',
    label: 'Uruguay',
    translationName: { en: 'Uruguay', ar: 'أوروغواي' },
  },
  {
    value: 'Uzbekistan',
    label: 'Uzbekistan',
    translationName: { en: 'Uzbekistan', ar: 'أوزباكستان' },
  },
  {
    value: 'Vanuatu',
    label: 'Vanuatu',
    translationName: { en: 'Vanuatu', ar: 'فانواتو' },
  },
  {
    value: 'Venezuela (Bolivarian Republic of)',
    label: 'Venezuela',
    translationName: { en: 'Venezuela', ar: 'فنزويلا' },
  },
  {
    value: 'Viet Nam',
    label: 'Vietnam',
    translationName: { en: 'Vietnam', ar: 'فيتنام' },
  },
  {
    value: 'Virgin Islands (British)',
    label: 'British Virgin Islands',
    translationName: { en: 'British Virgin Islands', ar: 'جزر فيرجن البريطانية ' },
  },
  {
    value: 'Virgin Islands (U.S.)',
    label: 'United States Virgin Islands',
    translationName: {
      en: 'United States Virgin Islands',
      ar: 'جزر فيرجن التابعة للولايات المتحدة',
    },
  },
  {
    value: 'Wallis and Futuna',
    label: 'Wallis and Futuna',
    translationName: { en: 'Wallis and Futuna', ar: 'واليس وفوتونا' },
  },
  {
    value: 'Western Sahara',
    label: 'Western Sahara',
    translationName: { en: 'Western Sahara', ar: 'الصحراء الغربية' },
  },
  {
    value: 'Yemen',
    label: 'Yemen',
    translationName: { en: 'Yemen', ar: 'اليمن' },
  },
  {
    value: 'Zambia',
    label: 'Zambia',
    translationName: { en: 'Zambia', ar: 'زامبيا' },
  },
  {
    value: 'Zimbabwe',
    label: 'Zimbabwe',
    translationName: { en: 'Zimbabwe', ar: 'زمبابوي' },
  },
];
