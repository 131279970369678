import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Alert } from '../../../../components/common/Alert/Alert';
import { Button } from '../../../../components/common/Button/Button';
import { FormTextInput } from '../../../../components/FormFields/FormTextInput';

export const EmailChangeForm = ({
  onSubmit, email, status,
}) => {
  const { t } = useTranslation('application', { keyPrefix: 'forms' });

  const formValidationSchema = Yup.object().shape({
    email: Yup.string().email(t('email.validationMsg')).required(t('email.requiredMsg')),
  });

  return (
    <Formik
      initialValues={{
        email: email || '',
      }}
      validationSchema={formValidationSchema}
      onSubmit={(values) => onSubmit(values)}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ dirty }) => (
        <Form>
          <FormTextInput name="email" label={t('email.label')} />
          <Button className="form__submit-btn" type="primary" buttonType="submit" text={t('saveEmail')} disabled={!dirty} />
          {status.type !== '' && (
          <Alert type={status.type}>{status.message}</Alert>
          )}
        </Form>
      )}
    </Formik>
  );
};
