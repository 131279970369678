import React from 'react';
import { Row } from 'react-grid-system';
import { HowToBrewFilters } from '../../components/HowToBrew/HowToBrewFilters/HowToBrewFilters';
import { HowToBrewStepper } from '../../components/HowToBrew/HowToBrewStepper/HowToBrewStepper';
import { MainLayout } from '../../components/Layout/MainLayout';

import './howToBrewPage.scss';

export const HowToBrewPage = () => (
  <MainLayout fluid className="how-to-brew-page">
    <Row style={{ marginLeft: 0, marginRight: 0, paddingBottom: '5%' }}>
      <HowToBrewFilters />
    </Row>
    <HowToBrewStepper />
  </MainLayout>
);
