import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, NavLink } from 'react-router-dom';

import Logo from '../../assets/icons/Logo.svg';
import HamburgerIcon from '../../assets/icons/hamburger-menu-2.svg';
import UserIcon from '../../assets/icons/user-icon-2.svg';
import SearchIcon from '../../assets/icons/search.svg';
import { Text } from '../Text/Text';
import { closeMenu, open } from '../../features/menu/menuSlice';
import { toggleCartModal } from '../../features/cart/cartSlice';
import { useAuth } from '../../hooks/useAuth';
import { api, useLogoutUserMutation } from '../../services/api';
import { Expandable } from '../common/Expandable/Expandable';
import { CurrentUser } from '../../helpers/CurrentUser';
import { Button } from '../common/Button/Button';
import { capitalizeFirstLetter } from '../../helpers/textHelpers';
import { CountCartIcon } from '../Cart/CountCartIcon';
import { navbarSubLinks } from '../../utils/navbar';
import { Input } from '../inputs/Input/Input';
import { setFilter } from '../../features/products/productsSlice';
import { debounce } from '../../helpers/debounce';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';

import './Navbar.scss';

const STICKY_THRESHOLD_IN_PIXELS = 50;

export const Navbar = ({ showSearchBar }) => {
  const { t, i18n } = useTranslation('application');
  const currentLanguage = i18n.language;
  const isArabic = currentLanguage === 'ar';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useAuth();
  const [logoutUser] = useLogoutUserMutation();

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsSticky(window.scrollY > STICKY_THRESHOLD_IN_PIXELS);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleUserLogout = async () => {
    try {
      await logoutUser();
      CurrentUser.delete();
      dispatch(api.util.resetApiState());
    } catch (err) {
      console.error(err);
    }
  };

  const handleMenuOpen = () => {
    dispatch(open());
  };

  const handleSearch = useCallback(debounce((ev) => {
    const obj = { key: 'searchPhrase', value: ev.target.value };
    dispatch(setFilter(obj));
    const productsListElement = document.getElementById('products-list');
    if (productsListElement) {
      productsListElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, 500), []);

  const searchSection = () => showSearchBar && (
    <div className="search-container">
      <div className="search-bar">
        <Input
          type="search"
          placeholder={t('filtering.search')}
          customWrapperClass="search-input"
          onChange={(ev) => handleSearch(ev)}
        />
      </div>
      <div
        className={`search-icon ${isArabic ? 'align-left' : 'align-right'}`}
      >
        <SearchIcon />
      </div>
    </div>
  );

  return (
    <header className={`${isSticky && 'sticky'}`}>
      <nav className="navbar">
        <div className="nav-links-container">
          <Link to="/" className="logo">
            <Logo />
            <Text
              type="headline2"
              text={capitalizeFirstLetter(t('common.hi'))}
            />
          </Link>

          <div className="nav-links">
            {navbarSubLinks.map((item) => (
              <NavLink
                key={item.id}
                to={item.path}
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              >
                {t(`menu.${item.id}`)}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="navbar__items">
          <span className="show-desktop">
            {searchSection()}
          </span>
          <LanguageSwitcher />
          <Expandable
            variant="dropdown"
            label={<UserIcon className="navbar__user_icon" />}
          >
            <div className="custom-select__options profile-options-dropdown">
              <Button
                type="naked"
                className="custom-select__option"
                text={capitalizeFirstLetter(t('common.profile'))}
                onClick={() => navigate('/profile/profile_details')}
              />
              {user ? (
                <Button
                  type="naked"
                  className="custom-select__option"
                  text={capitalizeFirstLetter(t('common.logOut'))}
                  onClick={handleUserLogout}
                />
              ) : (
                <Button
                  type="naked"
                  className="custom-select__option"
                  text={capitalizeFirstLetter(t('common.logIn'))}
                  onClick={() => navigate('/login', {
                    state: { prevPath: window.location.pathname },
                  })}
                />
              )}
            </div>
          </Expandable>
          <CountCartIcon onClick={() => dispatch(toggleCartModal())} />
          <HamburgerIcon
            style={{ cursor: 'pointer' }}
            onClick={() => handleMenuOpen()}
          />
          <CloseMenuWindow onClose={() => dispatch(closeMenu())} />
        </div>
      </nav>

      {/* Mobile Search Section */}
      <span className="show-mobile">
        {searchSection()}
      </span>
    </header>
  );
};

const CloseMenuWindow = ({ onClose }) => {
  const isOpen = useSelector((state) => state.menu.isOpen);
  return (
    <div
      className={`${isOpen && 'menuBackContainer'}`}
      onClick={onClose}
      role="button"
      tabIndex="0"
      onKeyDown={(e) => {
        if (e.key === 'M') {
          onClose();
        }
      }}
    >
      {}
    </div>
  );
};
