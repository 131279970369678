export const navbarSubLinks = [
  {
    path: '/how_to_brew',
    id: 'howToBrew',
  },
  {
    path: '/quiz',
    id: 'quiz',
  },
  {
    path: '/locations',
    id: 'locations',
  },
];
