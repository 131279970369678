import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { setConfiguration } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Menu } from './components/Menu/Menu';
import { Cart } from './components/Cart/Cart';
import { LoginPage } from './pages/LoginPage';
import { ProductsPage } from './pages/ProductsPage/ProductsPage';
import { QuizPage } from './pages/QuizPage';
import { HowToBrewPage } from './pages/HowToBrew/HowToBrewPage';
import { OrderFeedback } from './pages/OrderFeedback/OrderFeedback';
import { Modal } from './components/Modal/Modal';
import { Overlay } from './components/Overlay/Overlay';
import { ProfilePage } from './pages/ProfilePage/ProfilePage';
import { ProtectedRoute } from './helpers/protectedRoute';
import { ProfileDetails } from './pages/ProfilePage/ProfileDetails/ProfileDetails';
import { Addresses } from './pages/ProfilePage/Addresses/Addresses';
import { OrdersHistory } from './pages/ProfilePage/OrdersHistory/OrdersHistory';
import { ProductPreferences } from './pages/ProfilePage/ProductPreferences/ProductPreferences';
import { Notifications } from './pages/ProfilePage/Notifications/Notifications';
import { Wishlist } from './pages/ProfilePage/Wishlist/Wishlist';
import { PaymentSummary } from './pages/Payment/PaymentSummary';
import { OrderSummaryContainer } from './pages/Order/OrderSummaryContainer';
import { NotFoundPage } from './pages/404NotFound/NotFoundPage';
import { ProductPage } from './pages/SingleProduct/ProductPage';
import { PackagePage } from './pages/SinglePackage/PackagePage';
import { guestApi } from './services/guest';
import {
  getCookie,
  getExpiryDateValue,
  isCookieExpired,
  setCookie,
} from './helpers/cookieHelpers';
import { CheckoutPage } from './pages/Checkout/CheckoutPage';
import 'react-toastify/dist/ReactToastify.css';
import { useScrollToTop } from './hooks/useScrollToTop';
import { ErrorPage } from './pages/Error/ErrorPage';
import { BlogDescription } from './pages/BlogDescription';
import { AboutUs } from './pages/StaticPages/AboutUs';
import { Blog } from './pages/Blog';
import { Privacy } from './pages/StaticPages/Privacy';
import { FaqPage } from './pages/StaticPages/Faq';
import { Contact } from './pages/Contact/Contact';
import { Locations } from './pages/Locations/Locations';
import { useUnlockCartMutation, cartApi } from './services/cart';
import {
  setCurrentStep,
  setPendingPayment,
} from './features/checkout/checkoutSlice';
import { API_ROOT } from './config';
import { CurrentUser } from './helpers/CurrentUser';
import { useGoogleSessionMutation } from './services/api';
import { PromotionsModal } from './components/PromotionsModal';
import { CookieBanner } from './components/CookieBanner/CookieBanner';

setConfiguration({
  breakpoints: [576, 768, 1024, 1200, 1600],
});

/**
 * App root component
 *
 * @returns {HTMLElement} Returns app root element
 */
export const App = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('application');
  const currentStep = useSelector((state) => state.checkout.currentStep);
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = useLocation();
  const [unlockCart] = useUnlockCartMutation();
  const isPaymentPending = useSelector((state) => state.checkout.pendingPayment);
  let guestSessionCookieTimeout;

  const setGuestUserSessionCookie = () => {
    const result = dispatch(guestApi.endpoints.getGuestSession.initiate());
    result.then((payload) => {
      const cookieExpiryDate = payload.data.guest_session_token_expire_in;
      setCookie('guest_session_token', payload.data.guest_session_token, {
        expiryIn: cookieExpiryDate,
      });
      const expiryDate = getExpiryDateValue(cookieExpiryDate);
      const msToExpire = new Date(expiryDate).getTime() - Date.now();
      setCookie('guest_session_token_expiry', expiryDate.toUTCString(), {
        expiryIn: cookieExpiryDate,
      });
      guestSessionCookieTimeout = setTimeout(() => {
        setGuestUserSessionCookie();
      }, msToExpire);
    });
  };

  useEffect(() => {
    if (getCookie('guest_session_token')) {
      if (isCookieExpired('guest_session_token_expiry')) {
        setGuestUserSessionCookie();
      }
    } else {
      setGuestUserSessionCookie();
    }

    return () => {
      clearTimeout(guestSessionCookieTimeout);
    };
  }, []);

  useScrollToTop({
    dependency: pathname,
  });

  useEffect(() => {
    if (
      !pathname.startsWith('/checkout') && currentStep === 3 && isPaymentPending
    ) {
      dispatch(setPendingPayment(false));
      unlockCart();
      dispatch(setCurrentStep(1));
    }
  }, [pathname, currentStep, isPaymentPending]);

  const handleBrowserClose = () => {
    // eslint-disable-next-line no-useless-return
    if (currentStep !== 3) return;
    if (document.visibilityState === 'hidden') {
      dispatch(setPendingPayment(false));
      const headers = {
        'Accept-Language': i18n.language,
      };
      const token = CurrentUser.get()?.token;
      const guestSessionToken = getCookie('guest_session_token');
      if (guestSessionToken) {
        // eslint-disable-next-line immutable/no-mutation
        headers['Guest-Session-Token'] = guestSessionToken;
      }
      if (token) {
        // eslint-disable-next-line immutable/no-mutation
        headers['Authentication-Token'] = token;
      }
      const blob = new Blob([], headers);
      navigator.sendBeacon(`${API_ROOT}/v1/users/cancel_last_order`, blob);
    }
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', handleBrowserClose);

    return () => {
      window.removeEventListener('visibilitychange', handleBrowserClose);
    };
  }, [currentStep, isPaymentPending]);

  const [confirmCode] = useGoogleSessionMutation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const otp = params.get('otp_code');
    const result = params.get('result');
    const email = params.get('user_email');
    if (!result) return;
    if (result !== 'success') {
      navigate('/login');
      return;
    }
    const auth = async () => {
      const payloadSchema = {
        google_session: {
          email,
          otp_code: otp,
        },
      };
      try {
        const confirmCodeResult = await confirmCode(payloadSchema).unwrap();
        CurrentUser.set(confirmCodeResult?.data);
        if (location.state !== null) {
          navigate(location.state.prevPath);
        } else {
          navigate('/');
        }
        dispatch(cartApi.util.invalidateTags(['CartItems']));
      } catch (err) {
        const error = Object.values(err?.data?.errors)?.[0];
        navigate('/login?google-error');
        toast(error.join('\n'), { type: 'error' });
      }
    };
    auth();
  }, []);

  return (
    <div className="App">
      <PromotionsModal />
      <CookieBanner />
      <Helmet>
        <title>{t('seo.home.title')}</title>
        <meta name="description" content={t('seo.home.description')} />
      </Helmet>
      <Routes>
        <Route path="/" element={<ProductsPage />} />
        <Route path="/quiz/:step" element={<QuizPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="quiz" element={<QuizPage />} />
        <Route path="products">
          <Route index element={<ProductsPage />} />
          <Route path=":productId" element={<ProductPage />} />
        </Route>
        <Route path="/packages/:packageId" element={<PackagePage />} />
        <Route path="how_to_brew" element={<HowToBrewPage />}>
          <Route index element={<HowToBrewPage />} />
          <Route path=":productId" element={<HowToBrewPage />} />
        </Route>
        <Route
          path="profile"
          element={
            (
              <ProtectedRoute redirectTo="/login">
                <ProfilePage />
              </ProtectedRoute>
            )
          }
        >
          <Route path="profile_details" element={<ProfileDetails />} />
          <Route path="addresses" element={<Addresses />} />
          <Route path="wishlist" element={<Wishlist />} />
          <Route path="orders_history" element={<OrdersHistory />} />
          <Route path="product_preferences" element={<ProductPreferences />} />
          <Route path="notifications" element={<Notifications />} />
        </Route>
        <Route path="payment/validate" element={<PaymentSummary />} />
        <Route path="order_feedback/:token" element={<OrderFeedback />} />
        <Route
          path="checkout"
          element={
            (
              <ProtectedRoute redirectTo="/login">
                <CheckoutPage />
              </ProtectedRoute>
            )
          }
        />
        <Route
          path="profile_details/orders/:id"
          element={<OrderSummaryContainer type="history" />}
        />
        <Route path="about_us" element={<AboutUs />} />
        <Route path="policy" element={<Privacy />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/:blogId" element={<BlogDescription />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="faq" element={<FaqPage />} />
        <Route path="contact" element={<Contact />} />
        <Route path="error" element={<ErrorPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Menu />
      <Cart />
      <Overlay />
      <Modal />
      <ToastContainer position="top-center" limit={3} />
    </div>
  );
};
